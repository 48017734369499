import { NavItem } from '@components/pages/layouts';
import styled from '@emotion/styled';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CollectionsIcon from '@mui/icons-material/Collections';
import CropIcon from '@mui/icons-material/Crop';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MailIcon from '@mui/icons-material/Mail';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ScienceIcon from '@mui/icons-material/Science';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import { useRouter } from 'next/router';

type StyledNavLabelProps = {
  isSelected: boolean;
};

const StyledNavLabel = styled.p<StyledNavLabelProps>`
  display: flex;
  margin-left: 14px;
  font-weight: ${(p) => (p.isSelected ? 600 : 400)};
`;

type StyledNavLinkProps = {
  isSelected: boolean;
};

const StyledNavLink = styled.a<StyledNavLinkProps>`
  ${({ theme: { colors }, isSelected }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s ease-out;

    background-color: ${
      isSelected
        ? colors.sidebarSelectedBackground
        : colors.sidebarUnselectedBackground
    };

    color: ${
      isSelected ? colors.sidebarSelectedText : colors.sidebarUnselectedText
    };

    padding: 12px 4px 12px 24px;

    cursor: pointer;
    font-size: 14px;

    :hover {
      color: ${colors.white};
    }

    :focus-visible {
      outline: 1px auto ${colors.white};
    }
  `}
`;

type AdminLinkType = {
  label: string;
  href: string;
  IconComponent?: (props: SvgIconProps) => JSX.Element;
};

const MANAGER_BUSINESS_LINKS: AdminLinkType[] = [
  {
    label: 'Admin Home',
    href: '/admin',
    IconComponent: HomeIcon,
  },
  {
    href: '/admin/shipments',
    label: 'Shipments',
    IconComponent: LocalShippingIcon,
  },
  {
    href: '/admin/events',
    label: 'Dock Events',
    IconComponent: CalendarMonthIcon,
  },
  {
    href: '/admin/items',
    label: 'Item Names',
    IconComponent: AddAPhotoIcon,
  },
  {
    href: '/cv/datasets',
    label: 'CV Datapool Explorer',
    IconComponent: CollectionsIcon,
  },
  {
    href: '/admin/ops_tasks',
    label: 'Operations Tasks',
    IconComponent: NoteAltIcon,
  },
  {
    href: '/admin/edit-labels',
    label: 'Edit Labels',
    IconComponent: BorderColorIcon,
  },
  {
    href: '/admin/labels-edit',
    label: 'Edit Labels V2',
    IconComponent: BorderColorIcon,
  },
  {
    href: '/admin/extract-video',
    label: 'Video extraction',
    IconComponent: VideoCameraBackIcon,
  },
  {
    href: '/admin/gist',
    label: 'Gist Tools',
    IconComponent: CloudUploadIcon,
  },
  {
    href: '/admin/edit-features/user',
    label: 'Edit Features',
    IconComponent: ScienceIcon,
  },
  {
    href: '/admin/businesses',
    label: 'Businesses',
    IconComponent: CurrencyExchangeIcon,
  },
  {
    href: '/admin/user-management',
    label: 'User Management',
    IconComponent: MailIcon,
  },
  {
    href: '/admin/label-templates',
    label: 'Templates (Beta)',
    IconComponent: CropIcon,
  },
  {
    href: '/admin/task-backlog',
    label: 'Task Backlog',
    IconComponent: ListIcon,
  },
];

const AdminLeftMenuBusinessData = (): JSX.Element => {
  const { route } = useRouter();

  return (
    <>
      {MANAGER_BUSINESS_LINKS.map(({ label, href, IconComponent }) => {
        const isSelected =
          href === '/' || href === '/admin'
            ? href === route
            : route.includes(href);

        return (
          <NavItem key={label} href={href}>
            <StyledNavLink isSelected={isSelected}>
              {IconComponent && <IconComponent fontSize='small' />}
              <StyledNavLabel isSelected={isSelected}>{label}</StyledNavLabel>
            </StyledNavLink>
          </NavItem>
        );
      })}
    </>
  );
};

export { AdminLeftMenuBusinessData };
