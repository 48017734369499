import { useContext } from 'react';
import styled from '@emotion/styled';

import SkeletonSettings from './skeleton-settings';
import { getSkeletonStyles } from './common';

import type { ISettings } from './common/settings';
import type { AtLeastOne } from '@kargo/types/generics';

type Size = 'small' | 'medium' | 'large';

type StyledContainerProps = {
  width?: number;
  height?: number;
  size?: Size;
  settings: ISettings;
};

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ theme: { sizing }, size, width, height }) => `
  border-radius: ${sizing.scale200};
  padding: 0 ${sizing.scale200};

  ${
    size
      ? `
      width: ${
        {
          small: sizing.scale2000,
          medium: sizing.scale3000,
          large: sizing.scale3000,
        }[size]
      };

      height: ${
        {
          small: sizing.scale2000,
          medium: sizing.scale2100,
          large: sizing.scale3000,
        }[size]
      };

      font-size: ${
        {
          small: sizing.scale1400,
          medium: sizing.scale1600,
          large: sizing.scale1800,
        }[size]
      };
    `
      : `
      width: ${width}px;
      height ${height ? height : width}px;
    `
  }
`}
${({ theme: { colors }, settings }) => getSkeletonStyles(settings, colors)}
}`;

type CustomSizeProps = {
  width: number;
  size: Size;
};

type Props = AtLeastOne<CustomSizeProps> & { height?: number };

const DoorLabel = (props: Props) => {
  const settings = useContext(SkeletonSettings);

  return <StyledContainer {...props} settings={settings} />;
};

export default DoorLabel;
