import styled from '@emotion/styled';

export type Direction =
  | 'shipping'
  | 'shipping-highlight'
  | 'receiving'
  | 'receiving-highlight'
  | 'unknown'
  | 'neutral'
  | 'mixed'
  | 'unused';

type Size = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';

const StyledNumber = styled.p`
  line-height: 25px;
`;

const StyledLabel = styled.p`
  ${({ theme: { colors, sizing } }) => `
    color: ${colors.white};
    font-size: ${sizing.scale600};
    font-weight: 800;
    margin-bottom: ${sizing.scale300};
    text-transform: uppercase;
  `}
`;

type StyledBaseBadgeProps = {
  size: Size;
  direction: Direction;
};

const StyledBaseBadge = styled.div<StyledBaseBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1.5px solid;
  border-radius: 3px;

  ${({ theme: { sizing, colors }, size, direction }) => `
    width: ${
      {
        extraSmall: sizing.scale600,
        small: sizing.scale800,
        medium: sizing.scale900,
        large: sizing.scale1200,
        extraLarge: sizing.scale1400,
      }[size]
    };
    height: ${
      {
        extraSmall: sizing.scale600,
        small: sizing.scale800,
        medium: sizing.scale900,
        large: sizing.scale1200,
        extraLarge: sizing.scale1400,
      }[size]
    };

    font-size: ${
      {
        extraSmall: sizing.scale500,
        small: sizing.scale500,
        medium: sizing.scale600,
        large: sizing.scale700,
        extraLarge: sizing.scale900,
      }[size]
    };

    font-weight: ${
      {
        extraSmall: 500,
        small: 600,
        medium: 600,
        large: 600,
        extraLarge: 700,
      }[size]
    };

    color: ${
      {
        shipping: colors.dockBadgeShipping,
        'shipping-highlight': colors.dockBadgeShipping,
        receiving: colors.dockBadgeReceiving,
        'receiving-highlight': colors.dockBadgeReceiving,
        mixed: colors.dockBadgeMixed,
        neutral: colors.dockBadgeIdle,
        unknown: colors.dockBadgeUnused,
        unused: colors.dockBadgeUnused,
      }[direction]
    };

    border-color: ${
      {
        shipping: colors.dockBadgeShipping,
        'shipping-highlight': colors.dockBadgeShipping,
        receiving: colors.dockBadgeReceiving,
        'receiving-highlight': colors.dockBadgeReceiving,
        mixed: colors.dockBadgeMixed,
        neutral: colors.dockBadgeIdle,
        unknown: colors.dockBadgeUnused,
        unused: colors.dockBadgeUnused,
      }[direction]
    };
  `}
`;

type StyledBadgeProps = StyledBaseBadgeProps & {
  children: React.ReactNode;
};

const StyledKargoBadge = styled(({ children, ...rest }: StyledBadgeProps) => (
  <StyledBaseBadge {...rest}>{children}</StyledBaseBadge>
))`
  position: absolute;
  top: -15%;
  left: -15%;
`;

const StyledYardBadge = styled(({ children, ...rest }: StyledBadgeProps) => (
  <StyledBaseBadge {...rest}>{children}</StyledBaseBadge>
))`
  position: absolute;
  top: -15%;
  left: 75%;
`;

const StyledContainer = styled.div<Omit<DoorLabelProps, 'name'>>`
  ${({ theme: { sizing, colors }, size, direction, onClick, disabled }) => `
    position: relative;
    border-radius: ${sizing.scale200};
    padding: 0 ${sizing.scale200};
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: ${onClick ? 'pointer' : 'inherit'};
    width: fit-content;

    min-width: ${
      {
        extraSmall: sizing.scale1300,
        small: sizing.scale2000,
        medium: sizing.scale3000,
        large: sizing.scale3000,
        extraLarge: sizing.scale4000,
      }[size!]
    };
    height: ${
      {
        extraSmall: sizing.scale1300,
        small: sizing.scale2000,
        medium: sizing.scale2100,
        large: sizing.scale3000,
        extraLarge: sizing.scale4000,
      }[size!]
    };
    background: ${
      disabled
        ? colors.doorIdle
        : {
            shipping: colors.doorShippingBackground,
            'shipping-highlight': colors.doorShippingBackgroundHighlight,
            receiving: colors.doorReceivingBackground,
            'receiving-highlight': colors.doorReceivingBackgroundHighlight,
            mixed: colors.doorNeutralBackground,
            neutral: colors.doorNeutralBackground,
            unknown: colors.doorUnusedBackground,
            unused: colors.doorUnusedBackground,
          }[direction]
    };
    color: ${
      {
        shipping: colors.doorShippingColor,
        'shipping-highlight': colors.doorShippingColorBlue,
        receiving: colors.doorReceivingColor,
        'receiving-highlight': colors.doorReceivingColorGreen,
        mixed: colors.doorNeutralColor,
        unknown: colors.doorNeutralColor,
        neutral: colors.doorNeutralColor,
        unused: colors.doorUnusedColor,
      }[direction]
    };

    ${StyledNumber} {
      font-size: ${
        {
          extraSmall: sizing.scale700,
          small: sizing.scale1000,
          medium: sizing.scale1400,
          large: sizing.scale1500,
          extraLarge: sizing.scale1500,
        }[size!]
      };
      font-weight: ${
        size
          ? {
              extraSmall: 500,
              small: 200,
              medium: 200,
              large: 200,
              extraLarge: 800,
            }[size]
          : 200
      }
  `}
`;

type DoorLabelProps = {
  direction: Direction;
  size?: Size;
  name: string | undefined | null;
  isKargoDoor?: boolean;
  isParkingDoor?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

const DoorLabel = ({
  name,
  direction = 'neutral',
  size = 'medium',
  isKargoDoor,
  isParkingDoor,
  onClick,
  disabled = false,
}: DoorLabelProps) => {
  return (
    <StyledContainer
      direction={direction}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      {isKargoDoor && (
        <StyledKargoBadge direction={direction} size={size}>
          K
        </StyledKargoBadge>
      )}
      {isParkingDoor && (
        <StyledYardBadge direction={direction} size={size}>
          Y
        </StyledYardBadge>
      )}
      {size === 'extraLarge' && <StyledLabel>Door</StyledLabel>}
      <StyledNumber>{name || '—'}</StyledNumber>
    </StyledContainer>
  );
};

export default DoorLabel;
