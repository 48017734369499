import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { useRouter } from 'next/router';

type SIZE = 'small' | 'medium' | 'large';

type Breadcrumb = {
  breadcrumb: string;
  href: string;
};

type BreadcrumbsProps = {
  rootElement?: JSX.Element;
  size?: SIZE;
  items?: Array<React.ReactNode>;
};

const BreadCrumbItem = styled.div`
  display: flex;
  margin-right: 10px;
  text-transform: capitalize;

  span {
    margin-right: 10px;
  }

  &:last-of-type {
    a {
      cursor: default;
    }
  }
`;

const PathList = styled.div<{ size: SIZE }>`
  ${({ theme: { sizing }, size }) => `
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: ${
      {
        small: sizing.scale600,
        medium: sizing.scale700,
        large: sizing.scale800,
      }[size]
    };
  `}
`;

const Breadcrumbs = ({
  rootElement,
  size = 'medium',
  items,
}: BreadcrumbsProps) => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<Array<Breadcrumb> | null>(
    null,
  );

  const getPathFromUrl = (url: string): string => {
    return url.split(/[?#]/)[0];
  };

  const convertBreadcrumb = (title: string): React.ReactNode => {
    const transformedTitle = getPathFromUrl(title);

    return decodeURI(transformedTitle);
  };

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split('/');
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return {
          breadcrumb: path,
          href: '/' + linkPath.slice(0, i + 1).join('/'),
        };
      });

      setBreadcrumbs(pathArray);
    }
  }, [router]);

  if (!breadcrumbs) {
    return null;
  }

  return (
    <PathList aria-label='breadcrumbs' size={size}>
      {rootElement && (
        <BreadCrumbItem key={'root'}>{rootElement}</BreadCrumbItem>
      )}
      {items
        ? items
        : breadcrumbs.length >= 1 &&
          breadcrumbs.map(({ breadcrumb, href }) => {
            return (
              <BreadCrumbItem key={href}>
                <span>/</span>
                <Link href={href}>
                  <a>
                    {breadcrumb === '' ? 'Home' : convertBreadcrumb(breadcrumb)}
                  </a>
                </Link>
              </BreadCrumbItem>
            );
          })}
    </PathList>
  );
};

export { Breadcrumbs, BreadCrumbItem };
