import { useContext } from 'react';
import styled from '@emotion/styled';
import { getSkeletonStyles } from './common';
import type { ISettings } from './common/settings';
import SkeletonSettings from './skeleton-settings';

type Sizes = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

type Props = {
  size?: Sizes;
  length?: Sizes;
};

const PlaceHolder = styled.div<{
  size: Sizes;
  length: Sizes;
  settings: ISettings;
}>`
  ${({ theme: { sizing }, size, length }) => `
  width: ${
    {
      xxsmall: '5%',
      xsmall: '10%',
      small: '25%',
      medium: '50%',
      large: '75%',
      xlarge: '100%',
    }[length]
  };
  margin: ${
    size === 'xxsmall'
      ? `-3px 0 3px 0`
      : `${sizing.scale100} 0 ${sizing.scale400} 0`
  };
  height: ${
    {
      xxsmall: sizing.scale600,
      xsmall: sizing.scale700,
      small: sizing.scale900,
      medium: sizing.scale1200,
      large: sizing.scale1600,
      xlarge: sizing.scale3000,
    }[size]
  };
  display: block;
  border-radius: ${sizing.scale200};
`}
  ${({ theme: { colors }, settings }) => getSkeletonStyles(settings, colors)}
`;

const Text = ({ size = 'medium', length = 'xlarge', ...otherProps }: Props) => {
  const settings = useContext(SkeletonSettings);

  return (
    <PlaceHolder
      size={size}
      length={length}
      settings={settings}
      {...otherProps}
    />
  );
};

export default Text;
