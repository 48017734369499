import styled from '@emotion/styled';
import type { MenuItemProps } from '@mui/material';
import { MenuItem } from '@mui/material';

const KargoOption = styled((props: MenuItemProps) => (
  <MenuItem
    divider
    disableRipple
    {...props}
    classes={{
      ...props.classes,
      root: `kargo-option-root ${props.classes?.root}`,
      selected: `kargo-option-selected ${props.classes?.selected}`,
    }}
  />
))`
  white-space: normal;

  &.kargo-option-root {
    padding: ${(p) => p.theme.sizing.scale500};
    font-size: ${(p) => p.theme.sizing.scale700};
  }

  &.kargo-option-selected {
    background-color: ${(p) => p.theme.colors.menuFillHover};

    :hover,
    :focus {
      background-color: ${(p) => p.theme.colors.menuFillHover};
    }
  }

  :hover {
    background-color: ${(p) => p.theme.colors.menuFillHover};
  }
`;

export default KargoOption;
