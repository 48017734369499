import { useState, useMemo } from 'react';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

import { DAY_FORMAT } from '@utils/calendar';
import { DayPickerSingleDateController } from 'react-dates';

import { CalendarIcon } from '@heroicons/react/outline';
import { Popover, Button, Icon } from '@kargo/ui';

type Props = {
  date: Date | undefined;
  facilityTimezoneName?: string;
  onChange: (update: Date | undefined) => void;
  disabled?: boolean;
};

const ContentContainer = styled.div`
  font-size: 16px;
  white-space: nowrap;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
`;

const TimeContainer = styled.div`
  font-weight: 400;
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
`;

const DateContainer = styled.div`
  font-size: 13px;
  display: flex;
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

const DateButton = styled.div`
  border-radius: 5px;
  background: transparent;
  padding: 0 15px;

  &:hover {
    background: #fff;
  }
`;

const TimeInput = styled.div`
  padding: 15px;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
`;

const RowInput = styled.div`
  display: flex;
  padding: 0;
  justify-content: center;
  margin: 0;
  width: 100%;
  font-weight: 500;
`;

const RowOptions = styled.ul`
  overflow: auto;
  height: 100%;
`;

const RowSelection = styled.li<{ selected: boolean }>`
  display: flex;
  padding: 0;
  justify-content: center;
  margin: 0;
  border-radius: 4px;
  padding: 5px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.mono100 : 'transparent'};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : 'inherit'};

  &:hover {
    background: ${({ theme }) => theme.colors.mono100};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;

const HOURS = (function () {
  const h = [];
  for (let i = 1; i <= 12; i++) {
    h.push(i);
  }
  return h;
})();

const MINUTES = (function () {
  const m = [];
  for (let i = 0; i < 60; i++) {
    m.push(i);
  }
  return m;
})();

const DateTimePicker = ({
  date: dateProp,
  facilityTimezoneName,
  onChange,
  disabled = false,
}: Props) => {
  let momentDate = moment(dateProp);
  if (facilityTimezoneName) {
    momentDate = moment(dateProp).tz(facilityTimezoneName);
  }

  const date = momentDate;
  const [focused, setFocused] = useState(false);
  const isPM = useMemo(() => {
    if (!date) return false;
    return date.hours() >= 12;
  }, [date]);

  const hours = date?.hours();
  if (hours === undefined) return null;
  const selectedHour = isPM ? hours - 12 : hours;

  return (
    <Popover
      buttonStyle={{ height: '100%' }}
      button={
        !date ? (
          <Button variant={['outline']} kind='secondary' asDiv>
            <Icon
              size='12px'
              icon={CalendarIcon}
              style={{ marginRight: '10px' }}
            />
            Select Date & Time
          </Button>
        ) : (
          <DateButton>
            <TimeContainer>{moment(date).format('h:mma')}</TimeContainer>
            <DateContainer>{moment(date).calendar(DAY_FORMAT)}</DateContainer>
          </DateButton>
        )
      }
    >
      <ContentContainer>
        <DayPickerSingleDateController
          initialVisibleMonth={null}
          date={date ? moment(date) : null}
          numberOfMonths={1}
          onDateChange={(update) => {
            if (disabled === true) return;

            if (!update || !date) return;
            const updateDate = new Date(moment(date).toDate());
            updateDate.setMonth(update?.month());
            updateDate.setDate(update?.date());
            updateDate.setFullYear(update?.year());
            onChange(updateDate);
          }}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          noBorder={true}
          hideKeyboardShortcutsPanel={true}
        />

        <TimeInput>
          <RowInput>Hour:</RowInput>
          <RowInput>Minute:</RowInput>
          <RowInput>AM/PM:</RowInput>

          <RowOptions>
            {HOURS.map((hour) => {
              return (
                <RowSelection
                  key={hour}
                  selected={
                    // 12 is special, because it can be true for 12 or 0
                    selectedHour === hour || (hour === 12 && selectedHour === 0)
                  }
                  onClick={() => {
                    if (!date) return;
                    const updateDate = date;

                    // handle the case where it's 12.
                    // you don't want to change from AM to PM
                    // when it's twelve
                    if (hour === 12) {
                      if (isPM) updateDate.hours(12);
                      else updateDate.hours(0);
                    }
                    // this is any other case.
                    else {
                      if (date.hours() < 12) updateDate.hours(hour);
                      else updateDate.hours(hour + 12);
                    }
                    onChange(updateDate.toDate());
                  }}
                >
                  {String(hour).padStart(2, '0')}
                </RowSelection>
              );
            })}
          </RowOptions>

          <RowOptions>
            {MINUTES.map((minute) => {
              return (
                <RowSelection
                  key={minute}
                  selected={date.minutes() === minute}
                  onClick={() => {
                    if (!date) return;
                    const updateDate = date;
                    updateDate.minutes(minute);
                    onChange(updateDate.toDate());
                  }}
                >
                  {String(minute).padStart(2, '0')}
                </RowSelection>
              );
            })}
          </RowOptions>

          <RowOptions>
            <RowSelection
              selected={!isPM}
              onClick={() => {
                if (!isPM || !date) return;

                const updateDate = date;
                updateDate.hours(date.hours() - 12);
                onChange(updateDate.toDate());
              }}
            >
              AM
            </RowSelection>
            <RowSelection
              selected={isPM}
              onClick={() => {
                if (!date || isPM) return;

                const updateDate = date;
                if (date.hours() === 12) updateDate.hours(12);
                else updateDate.hours(date.hours() + 12);
                onChange(updateDate.toDate());
              }}
            >
              PM
            </RowSelection>
          </RowOptions>
        </TimeInput>
      </ContentContainer>
    </Popover>
  );
};

export default DateTimePicker;
