const MANAGER_BUSINESS_DATA_PERMISSION = 'ops:manager_business_data';
const LABELER_PERMISSION = 'ops:write:ops_task';
const LABELER_ADMIN_PERMISSION = 'ops:write:ops_task_assignment';

export const isLabeler = (permissions: string[]) => {
  return permissions.includes(LABELER_PERMISSION);
};

export const isLabelerAdmin = (permissions: string[]) => {
  return permissions.includes(LABELER_ADMIN_PERMISSION);
};

export const isBusinessDataUser = (permissions: string[]) => {
  return permissions.includes(MANAGER_BUSINESS_DATA_PERMISSION);
};
