import styled from '@emotion/styled';

const RoundButton = styled.div`
  ${({ theme: { sizing, colors } }) => `
    display: flex;
    cursor: pointer;

    width: ${sizing.scale1500};
    height: ${sizing.scale1500};
    font-size: ${sizing.scale1000};
    border-radius: 50%;

    background-color: ${colors.gray200};
    color:  ${colors.black};
    text-align: center;
    justify-content: center;
    align-items: start;

    &:before {
      content: "...";
      line-height: ${sizing.scale900};
    }
  `}
`;

const ThreeDotMenu = () => {
  return <RoundButton />;
};

export default ThreeDotMenu;
