import { useState } from 'react';
import styled from '@emotion/styled';

import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';

import { CalendarIcon } from '@heroicons/react/outline';
import { Popover, Icon } from '@kargo/ui';

type Props = {
  date: Date | undefined;
  onChange: (update: Date | undefined) => void;
  disabled?: boolean;
};

const ContentContainer = styled.div`
  white-space: nowrap;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
`;

const DatePicker = ({ date, onChange, disabled = false }: Props) => {
  const [focused, setFocused] = useState(false);
  return (
    <Popover
      buttonStyle={{ height: '100%' }}
      button={<Icon size='16px' icon={CalendarIcon} />}
    >
      <ContentContainer>
        <DayPickerSingleDateController
          initialVisibleMonth={null}
          date={date ? moment(date) : null}
          numberOfMonths={1}
          onDateChange={(update) => {
            if (disabled === true) return;

            if (!update || !date) return;
            const updateDate = new Date(date);
            updateDate.setMonth(update?.month());
            updateDate.setDate(update?.date());
            updateDate.setFullYear(update?.year());
            onChange(updateDate);
          }}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          noBorder={true}
          hideKeyboardShortcutsPanel={true}
        />
      </ContentContainer>
    </Popover>
  );
};

export default DatePicker;
