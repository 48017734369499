import { useMemo } from 'react';
import { TextInput } from '@kargo/ui';

type Props = any;

const cleanNumber = (phoneNumber: string | undefined = '') => {
  return phoneNumber.replace(/\D/g, '').substring(0, 10);
};

const PhoneInput = ({
  style = {},
  className = '',
  value = '',
  onChange = () => {},
}: Props) => {
  const formattedNumber = useMemo(() => {
    const cleaned = cleanNumber(value);
    const parts = cleaned.match(/^(\d{0,3})?(\d{0,3})?(\d{0,4})$/);

    let output = '';
    if (parts?.[1]) {
      output = `(${parts[1]}`;
    }
    if (parts?.[2]) {
      output = `${output}) ${parts[2]}`;
    }
    if (parts?.[3]) {
      output = `${output}-${parts[3]}`;
    }

    return output;
  }, [value]);

  return (
    <TextInput
      type='tel'
      className={className}
      style={style}
      value={formattedNumber}
      onChange={onChange}
    />
  );
};

export default PhoneInput;
