import { AuthenticatedPage, Logo } from '@components';
import ApolloContainer from '@components/apollo-container';
import { KargoDrawer } from '@components/kargo-ui/drawer';
import { AdminLeftMenuContainer } from '@containers/admin/left-menu-container';
import styled from '@emotion/styled';
import { MenuOpen } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import { useState } from 'react';
import { AdminUserSettingsMenu } from './admin-header/admin-user-settings-menu';

const HEADER_HEIGHT = '60px';
const SIDE_NAV_WIDTH = '225px';

const StyledAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors.contentPrimary};
  background: ${(p) => p.theme.colors.backgroundPrimary};
  overflow: hidden;
`;

type StyledAdminContentProps = {
  isNavDrawerOpen?: boolean;
};

const StyledAdminContentContainer = styled.div<StyledAdminContentProps>`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${HEADER_HEIGHT});
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH})` : '100%'};
  margin-left: ${(p) => (p.isNavDrawerOpen ? `${SIDE_NAV_WIDTH}` : 0)};
  transition: all 0.2s ease-out;

  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.contentPrimary};
  overflow: auto;
`;

const StyledAdminEmptyHeader = styled.div`
  display: flex;
  align-items: center;
`;

type StyledAdminHeaderContainerProps = {
  isNavDrawerOpen?: boolean;
};

const StyledAdminHeaderContainer = styled.div<StyledAdminHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH} + 55px)` : '100%'};
  margin-left: ${(p) =>
    p.isNavDrawerOpen ? `calc(${SIDE_NAV_WIDTH} - 55px)` : 0};
  transition: all 0.2s ease-out;

  padding: 8px 24px;
  background: ${(p) => p.theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${(p) => p.theme.colors.borderPrimary};
`;

const StyledAdminHeaderMain = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledOpenNavDrawerIconButton = styled(IconButton)`
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
    background: none;
  }
`;

const StyledOpenNavDrawerIcon = styled(MenuOpen)`
  transform: scaleX(-1);
`;

const StyledNavContainer = styled.div`
  width: ${SIDE_NAV_WIDTH};
  min-height: 100vh;
  color: ${(p) => p.theme.colors.contentPrimary};
  background-color: ${(p) => p.theme.colors.sidebarBackground};
  overflow: auto;
`;

const StyledEmptyNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  height: ${HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledLogoLink = styled.a`
  margin-left: 26px;

  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledCloseNavDrawerIconButton = styled(IconButton)`
  padding-top: 10px;
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
  }

  :focus-visible {
    border-radius: 0;
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledCloseNavDrawerIcon = styled(MenuOpen)`
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
`;

const StyledSecondaryNavContainer = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 20px;
`;

const AdminLayout = ({
  children,
  title = '',
  SubNavigation,
}: {
  children: React.ReactElement;
  title: string | React.ReactElement;
  SubNavigation?: () => React.ReactElement;
}) => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true);

  return (
    <AuthenticatedPage>
      <ApolloContainer>
        <StyledAdminContainer>
          <StyledAdminHeaderContainer isNavDrawerOpen={isNavDrawerOpen}>
            <StyledAdminHeaderMain>
              <StyledOpenNavDrawerIconButton
                onClick={() => {
                  setIsNavDrawerOpen(true);
                }}
              >
                <StyledOpenNavDrawerIcon />
              </StyledOpenNavDrawerIconButton>

              <StyledTitle>{title}</StyledTitle>
            </StyledAdminHeaderMain>

            <AdminUserSettingsMenu />
          </StyledAdminHeaderContainer>

          <StyledAdminContentContainer isNavDrawerOpen={isNavDrawerOpen}>
            {SubNavigation && (
              <StyledSecondaryNavContainer>
                <SubNavigation />
              </StyledSecondaryNavContainer>
            )}

            {children}
          </StyledAdminContentContainer>

          <KargoDrawer
            variant='persistent'
            anchor='left'
            classes={{
              paper: 'dashboard-nav-drawer',
            }}
            open={isNavDrawerOpen}
          >
            <StyledNavContainer>
              <StyledNavLogoContainer>
                <Link href='/' passHref>
                  <StyledLogoLink>
                    <Logo />
                  </StyledLogoLink>
                </Link>

                <StyledCloseNavDrawerIconButton
                  onClick={() => {
                    setIsNavDrawerOpen(false);
                  }}
                >
                  <StyledCloseNavDrawerIcon />
                </StyledCloseNavDrawerIconButton>
              </StyledNavLogoContainer>

              <AdminLeftMenuContainer />
            </StyledNavContainer>
          </KargoDrawer>
        </StyledAdminContainer>
      </ApolloContainer>
    </AuthenticatedPage>
  );
};

const AdminEmptyNavLayout = ({
  children,
  title = '',
}: {
  children: React.ReactElement;
  title: string | React.ReactElement;
}) => {
  return (
    <AuthenticatedPage>
      <ApolloContainer>
        <StyledAdminContainer>
          <StyledAdminEmptyHeader>
            <StyledEmptyNavLogoContainer>
              <Link href='/' passHref>
                <StyledLogoLink>
                  <Logo />
                </StyledLogoLink>
              </Link>
            </StyledEmptyNavLogoContainer>

            <StyledAdminHeaderContainer>
              <StyledAdminHeaderMain>
                <StyledTitle>{title}</StyledTitle>
              </StyledAdminHeaderMain>

              <AdminUserSettingsMenu />
            </StyledAdminHeaderContainer>
          </StyledAdminEmptyHeader>

          <StyledAdminContentContainer>{children}</StyledAdminContentContainer>
        </StyledAdminContainer>
      </ApolloContainer>
    </AuthenticatedPage>
  );
};

export { AdminLayout, AdminEmptyNavLayout };
