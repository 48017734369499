import styled from '@emotion/styled';
import { Add } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import { useState } from 'react';

const StyledAddIconButtonContainer = styled(IconButton)`
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.internationalOrange700};
  transition: opacity 0.1s ease-in;

  :hover,
  :focus-visible {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.internationalOrange700};
    opacity: 0.6;
  }
`;

const StyledCreateActionsMenu = styled(Menu)`
  margin-top: 8px;

  & .create-actions-menu-list {
    padding: 0;
  }
`;

const StyledCreateActionsMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const StyledActionLink = styled.a`
  font-size: 0.875rem;
`;

const DashboardCreateActionsMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <StyledAddIconButtonContainer
        size='small'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Add />
      </StyledAddIconButtonContainer>

      <StyledCreateActionsMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        classes={{
          list: 'create-actions-menu-list',
        }}
      >
        <Link href={'/shipments/new'} passHref>
          <StyledCreateActionsMenuItem>
            <StyledActionLink>Schedule Shipment</StyledActionLink>
          </StyledCreateActionsMenuItem>
        </Link>
      </StyledCreateActionsMenu>
    </>
  );
};

export { DashboardCreateActionsMenu };
