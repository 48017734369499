import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

type Props = {
  width: number;
};

const svgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg)
  }
`;

const circleAnimation = keyframes`
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.svg<Props>`
  animation: 2s linear infinite ${svgAnimation};

  circle {
    animation: 1.4s ease-in-out infinite both ${circleAnimation};
  }

  ${({ theme: { colors, sizing }, width }) => `
    max-width: ${width};

    circle {
      display: block;
      fill: transparent;

      stroke: ${colors.gray400};
      stroke-linecap: round;

      stroke-dasharray: 283;
      stroke-dashoffset: 280;

      stroke-width: ${sizing.scale500};
      transform-origin: 50% 50%;
    }
  `}
`;

const Spinner = ({ width }: Props) => (
  <LoadingSpinner
    width={width}
    viewBox='0 0 100 100'
    xmlns='http://www.w3.org/2000/LoadingSpinner'
  >
    <circle cx='50' cy='50' r='45' />
  </LoadingSpinner>
);

export default Spinner;
