import styled from '@emotion/styled';

import { ClockIcon } from '@heroicons/react/outline';
import { Popover, Icon } from '@kargo/ui';

type Props = {
  date: Date | undefined;
  onChange: (update: Date | undefined) => void;
  disabled?: boolean;
  size?: 'hh:mm' | 'hh:mm:ss' | 'hh:mm:ss:mm';
};

const ContentContainer = styled.div`
  white-space: nowrap;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%);
  min-height: 0;
  min-width: 0;
  overflow: hidden;
`;

const TimeInput = styled.div`
  padding: 20px 30px 30px 10px;
`;

const Measures = styled.div`
  color: ${({ theme }) => theme.colors.contentSecondary};
  display: flex;
  width: 100%;
  font-weight: bold;
  padding-bottom: 15px;
`;

const MeasureField = styled.div`
  width: 90px;
  text-align: center;
`;

const Values = styled.div`
  display: flex;
  width: 100%;
`;

const ValueField = styled.div`
  width: 90px;
  text-align: center;
  height: 200px;
  overflow-y: scroll;
`;

const ValueFieldSelector = styled.div<{ selected?: boolean }>`
  ${({ theme: { colors }, selected }) => `
    background-color: ${selected ? colors.internationalOrange700 : 'white'};
    color: ${selected ? 'white' : 'black'};
    padding: 4px 0;
    cursor: pointer;
  `}
`;

const DateTimePicker = ({ date, onChange, size = 'hh:mm:ss' }: Props) => {
  const HOURS = Array.from(Array(24).keys());
  const MINUTES_SECONDS = Array.from(Array(60).keys());
  const MILISECONDS = Array.from(Array(1000).keys());

  const selectedHour = date?.getHours().toString();
  const selectedMinute = date?.getMinutes().toString();
  const selectedSecond = date?.getSeconds().toString();
  const selectedMilisecond = date?.getMilliseconds().toString();

  const updateDate = ({
    hour,
    minute,
    second,
    milisecond,
  }: {
    hour?: string;
    minute?: string;
    second?: string;
    milisecond?: string;
  }) => {
    const newDate = date ? new Date(date) : new Date();
    hour && newDate.setHours(Number(hour));
    minute && newDate.setMinutes(Number(minute));
    second && newDate.setSeconds(Number(second));
    milisecond && newDate.setMilliseconds(Number(milisecond));

    onChange(newDate);
  };

  const formatTime = (time: any) => String(time).padStart(2, '0');

  return (
    <Popover
      buttonStyle={{ height: '100%' }}
      button={<Icon size='16px' icon={ClockIcon} />}
    >
      <ContentContainer>
        <TimeInput>
          <Measures>
            <MeasureField>Hour</MeasureField>
            <MeasureField>Minute</MeasureField>
            {size === 'hh:mm:ss' && <MeasureField>Second</MeasureField>}
            {size === 'hh:mm:ss:mm' && <MeasureField>Milisecond</MeasureField>}
          </Measures>

          <Values>
            <ValueField>
              {HOURS.map((hour: number) => {
                const fmtHour = formatTime(hour);
                const fmtSelected = formatTime(selectedHour);

                return (
                  <ValueFieldSelector
                    key={hour}
                    selected={fmtHour === fmtSelected}
                    onClick={() => updateDate({ hour: fmtHour })}
                  >
                    {fmtHour}
                  </ValueFieldSelector>
                );
              })}
            </ValueField>

            <ValueField>
              {MINUTES_SECONDS.map((minute: number) => {
                const fmtMinute = formatTime(minute);
                const fmtSelected = formatTime(selectedMinute);

                return (
                  <ValueFieldSelector
                    key={minute}
                    selected={fmtMinute === fmtSelected}
                    onClick={() => updateDate({ minute: fmtMinute })}
                  >
                    {fmtMinute}
                  </ValueFieldSelector>
                );
              })}
            </ValueField>

            {size === 'hh:mm:ss' && (
              <ValueField>
                {MINUTES_SECONDS.map((second: number) => {
                  const fmtSecond = formatTime(second);
                  const fmtSelected = formatTime(selectedSecond);

                  return (
                    <ValueFieldSelector
                      key={second}
                      selected={fmtSecond === fmtSelected}
                      onClick={() => updateDate({ second: fmtSecond })}
                    >
                      {fmtSecond}
                    </ValueFieldSelector>
                  );
                })}
              </ValueField>
            )}

            {size === 'hh:mm:ss:mm' && (
              <ValueField>
                {MILISECONDS.map((milisecond: number) => {
                  const fmtMilisecond = formatTime(milisecond);
                  const fmtSelected = formatTime(selectedMilisecond);

                  return (
                    <ValueFieldSelector
                      key={milisecond}
                      selected={fmtMilisecond === fmtSelected}
                      onClick={() => updateDate({ milisecond: fmtMilisecond })}
                    >
                      {fmtMilisecond}
                    </ValueFieldSelector>
                  );
                })}
              </ValueField>
            )}
          </Values>
        </TimeInput>
      </ContentContainer>
    </Popover>
  );
};

export default DateTimePicker;
