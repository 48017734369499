import styled from '@emotion/styled';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline';

type Feedback = 'positive' | 'warning' | 'negative';

type FeedbackAsset = {
  color: string;
  icon: JSX.Element;
};

const FeedbackAssets: { [key: string]: FeedbackAsset } = {
  positive: { color: 'teal', icon: <CheckCircleIcon /> },
  warning: { color: 'yellow', icon: <ExclamationCircleIcon /> },
  negative: { color: 'red', icon: <XCircleIcon /> },
};

type Props = {
  type: Feedback;
  children: string;
};

const Container = styled.div<{ feedbackColor: string }>`
  ${({ theme: { colors, sizing }, feedbackColor }) => `
    display: flex;
    background-color: ${colors[`${feedbackColor}50`]};

    color: ${colors.contentPrimary};
    padding: ${sizing.scale500};
    border-radius: ${sizing.scale300};
    font-weight: 500;
    font-size: ${sizing.scale700};

    svg {
      color: ${colors[`${feedbackColor}400`]};
      margin-right: ${sizing.scale300};
      width: ${sizing.scale1000};
      height: ${sizing.scale1000};
    }
  `}
`;

const Alert = ({ type, children }: Props) => (
  <Container feedbackColor={FeedbackAssets[type].color}>
    {FeedbackAssets[type].icon}
    <span>{children}</span>
  </Container>
);

export default Alert;
