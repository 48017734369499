import styled from '@emotion/styled';

type Props = {
  max: number;
  min: number;
  step?: number;
  value: number;
  onChange: (value: number) => void;
};

const SliderInput = styled.input`
  position: relative;
  top: -4px;
  -webkit-appearance: none;
  width: 100%;
  height: ${({ theme }) => theme.sizing.scale200};
  background: ${({ theme }) => theme.colors.sliderBar};
  position: relative;
  outline: none;
  border-radius: 1px;

  &::-ms-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    height: ${({ theme }) => theme.sizing.scale800};
    width: ${({ theme }) => theme.sizing.scale800};
    background: ${({ theme }) => theme.colors.sliderButton};
    cursor: pointer;
    &:hover {
      height: ${({ theme }) => theme.sizing.scale900};
      width: ${({ theme }) => theme.sizing.scale900};
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    height: ${({ theme }) => theme.sizing.scale800};
    width: ${({ theme }) => theme.sizing.scale800};
    background: ${({ theme }) => theme.colors.sliderButton};
    cursor: pointer;
    &:hover {
      height: ${({ theme }) => theme.sizing.scale900};
      width: ${({ theme }) => theme.sizing.scale900};
    }
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    height: ${({ theme }) => theme.sizing.scale800};
    width: ${({ theme }) => theme.sizing.scale800};
    background: ${({ theme }) => theme.colors.sliderButton};
    cursor: pointer;
    &:hover {
      height: ${({ theme }) => theme.sizing.scale900};
      width: ${({ theme }) => theme.sizing.scale900};
    }
  }
`;

const Slider = ({ max, min, step = 1, value, onChange }: Props) => {
  return (
    <div>
      <SliderInput
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
};

export default Slider;
