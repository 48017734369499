import { useLayers } from '@kargo/context';
import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

const Overlay = styled.div<{ zIndex: number }>`
  z-index: ${({ zIndex }) => zIndex};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const useLayerID = ({
  createLayer,
  removeLayer,
}: {
  createLayer: (() => string) | undefined;
  removeLayer: ((id: string) => void) | undefined;
}) => {
  const [layerID, setLayerID] = useState<string | undefined>();
  useEffect(() => {
    if (!createLayer || !removeLayer) return;
    const id = createLayer();
    setLayerID(id);

    return () => removeLayer(id);
  }, [createLayer, removeLayer]);
  return layerID;
};

const useLayerZIndex = ({
  id,
  layerIDs,
  startAt,
}: {
  id: string | undefined;
  layerIDs: Array<string>;
  startAt: number;
}) => {
  return useMemo(() => {
    if (!layerIDs || !id) return 0;
    return layerIDs.findIndex((i) => id === i) + startAt;
  }, [id, layerIDs]);
};

export type LayerProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

const Layer = ({ children, className, onClick, ...props }: LayerProps) => {
  const { layerIDs, createLayer, removeLayer, startAt = 0 } = useLayers();
  const layerID = useLayerID({ removeLayer, createLayer });
  const zIndex = useLayerZIndex({ id: layerID, layerIDs, startAt });
  if (zIndex < 1 || !layerID) return null;
  return (
    <Overlay zIndex={zIndex} onClick={onClick} className={className} {...props}>
      {children}
    </Overlay>
  );
};

export default Layer;
