type Direction = 'normal' | 'reverse' | 'alternate' | 'alternate-reverse';
type Ease = 'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out';
type Count = number | 'infinite';
export interface ISettings {
  backgroundColor?: string;
  waveColor?: string;
  waveDirection?: Direction;
  waveSpeed?: number;
  waveEase?: Ease;
  waveCount?: Count;
}

const WAVE_DIRECTION = 'normal';
const WAVE_SPEED = 2.5;
const WAVE_EASE = 'ease-in-out';
const WAVE_COUNT = 'infinite';

export { WAVE_DIRECTION, WAVE_SPEED, WAVE_EASE, WAVE_COUNT };
