import { KargoOption } from '@components/kargo-ui/select/kargo-option';
import { KargoSelect } from '@components/kargo-ui/select/kargo-select';
import styled from '@emotion/styled';
import { ExpandMore } from '@mui/icons-material';
import { Link } from '@mui/material';
import type { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import type { SelectChangeEvent } from '@mui/material/Select';

const StyledFacilitySelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const StyledDashboardBreadcrumbs = styled((props: BreadcrumbsProps) => (
  <Breadcrumbs
    {...props}
    classes={{
      li: 'dashboard-header-navigation__breadcrumb_list-item',
      ...props.classes,
    }}
  />
))`
  & .dashboard-header-navigation__breadcrumb_list-item {
    display: flex;
  }
`;

const StyledKargoOption = styled(KargoOption)`
  color: ${(p) => p.theme.colors.menuFontDisabled};

  &.selected {
    background: inherit;
    color: ${(p) => p.theme.colors.menuFontSelected};
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.contentPrimary};
  font-size: 0.875rem;
`;

const StyledNonLinkText = styled.p`
  color: ${({ theme }) => theme.colors.contentPrimary};
  font-size: 0.875rem;
`;

export type DashboardBreadcrumb = { name: string | JSX.Element; uri?: string };

type FacilityOption = {
  label: string;
  value: {
    name: string | null;
    id: number;
    slug: string;
    businessId: number;
    businessSlug: string;
    timezoneName: string;
    timezoneAbbr: string;
  };
};

type Props = {
  breadcrumbs?: DashboardBreadcrumb[];
  facilityOptions: FacilityOption[];
  selectedFacilityIndex: number | undefined;
  onFacilitySelected: (facilityOption: FacilityOption) => void;
};

const DashboardHeaderNavigation = ({
  breadcrumbs,
  facilityOptions,
  selectedFacilityIndex,
  onFacilitySelected,
}: Props): JSX.Element => {
  return (
    <StyledDashboardBreadcrumbs>
      <StyledFacilitySelectionContainer>
        <KargoSelect
          value={facilityOptions[selectedFacilityIndex || 0].value.id}
          disableUnderline
          IconComponent={ExpandMore}
          placeholder='Facility'
          onChange={(e: SelectChangeEvent<unknown>) => {
            const selectedFacility = facilityOptions.find(
              (facility) => facility.value.id === e.target.value,
            );

            if (selectedFacility) {
              onFacilitySelected(selectedFacility);
            }
          }}
        >
          {facilityOptions.map((facility) => (
            <StyledKargoOption
              key={facility.label}
              value={facility.value.id}
              classes={{
                selected: 'selected',
              }}
            >
              {facility.label}
            </StyledKargoOption>
          ))}
        </KargoSelect>
      </StyledFacilitySelectionContainer>

      {breadcrumbs?.map(({ name, uri }, index, list) =>
        uri && index !== list.length - 1 ? (
          <StyledLink key={index} href={uri} underline='hover'>
            {name}
          </StyledLink>
        ) : (
          <StyledNonLinkText key={index}>{name}</StyledNonLinkText>
        ),
      )}
    </StyledDashboardBreadcrumbs>
  );
};

export { DashboardHeaderNavigation };
