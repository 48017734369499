import styled from '@emotion/styled';

type Props = {
  label?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  error?: string;
  style?: { [property: string]: string };
  className?: string;
};

const Container = styled.div`
  margin-bottom: 12px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelActions = styled.div`
  margin-left: 10px;
`;

const Label = styled.label`
  font-size: 14px;
`;

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.negative300};
`;

const FormInput = ({
  children,
  label,
  error,
  actions,
  style = {},
  className,
}: Props) => {
  return (
    <Container style={style} className={className}>
      <LabelContainer>
        {label && <Label>{label}</Label>}
        {actions && <LabelActions>{actions}</LabelActions>}
      </LabelContainer>
      {error && <Error>{error}</Error>}
      <div>{children}</div>
    </Container>
  );
};

export default FormInput;
