import type { Theme } from '@emotion/react';

const ScrollStyle = `
  ${({ sizing, colors }: Theme) => `
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: ${sizing.scale600};
      background-color: ${colors.gray200};
      border-radius: ${sizing.scale400};
    }

    ::-webkit-scrollbar:horizontal {
      height: ${sizing.scale600};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${sizing.scale400};
      border: 3px solid ${colors.gray200};
      background-color: ${colors.gray400};
    }

    ::-webkit-scrollbar-track {
      border-radius: ${sizing.scale400};
    }
  `};
`;

export default ScrollStyle;
