import Link from 'next/link';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import type {
  Column,
  RowData,
  RowDataAction,
  RowDataLink,
  RowType,
} from './types';

function isRowLink(row: RowData) {
  return (row as RowDataLink).linkTo !== undefined;
}

function isRowAction(row: RowData) {
  return (row as RowDataAction).onClick !== undefined;
}

type Props = {
  className?: string;
  columns: Array<Column>;
  rows: Array<RowData>;
  style?: any;
};

const StyledTable = styled.table`
  width: 100%;

  & > thead > tr > th {
      text-align: left;
      font-size: 0.8em;
      color: ${({ theme }) => theme.colors.contentPrimary};
      padding: ${({ theme }) => theme.sizing.scale800};
      padding-top: ${({ theme }) => theme.sizing.scale1400};
    }
  }
`;

const Row = styled.tr<{
  clickable: boolean;
  hoverable: boolean;
  highlight: boolean;
  padCells?: boolean;
  type: RowType;
}>`
  box-sizing: border-box;
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderSecondary}`};

  td {
    font-weight: 400;
    font-size: ${({ theme }) => theme.sizing.scale800};
    background: ${({ theme, highlight, type }) => {
      if (type === 'selected') return theme.colors.mono600;
      if (highlight) return theme.colors.tableRowHoverBackground;
      return theme.colors.tableBackground;
    }};
    padding: 0 ${({ theme }) => theme.sizing.scale600};
    padding-top: ${({ theme, padCells = true }) =>
      padCells ? theme.sizing.scale800 : 0};
    padding-bottom: ${({ theme, padCells = true }) =>
      padCells ? theme.sizing.scale800 : 0};
  }

  &:hover {
    td {
      background: ${({ hoverable, theme }) => {
        if (hoverable) return theme.colors.tableRowHoverBackground;
        return theme.colors.tableBackground;
      }};
      cursor: ${({ clickable }) => (clickable ? 'pointer' : 'inherit')};
    }
  }
`;

const TableLink = styled.a`
  padding: ${({ theme }) => theme.sizing.scale600};
  display: block;
`;

const isNullOrUndefined = (value: React.ReactNode) => {
  return value === undefined || value === null;
};

const Table = ({ columns, rows, style, className }: Props) => {
  const columnIds = useMemo(() => columns.map(({ id }) => id), [columns]);

  return (
    <StyledTable style={style} className={className}>
      <thead>
        <tr>
          {columns.map(({ label, id }) => (
            <th key={id}>{label}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, idx) => {
          const {
            data,
            type = 'default',
            onMouseEnter,
            onMouseLeave,
            highlight = false,
          } = row;

          const isAction = isRowAction(row);
          const isLink = isRowLink(row);
          if (!isLink && !isAction) {
            return (
              <Row
                key={idx}
                type={type}
                hoverable={
                  onMouseEnter !== undefined || onMouseLeave !== undefined
                }
                clickable={false}
                {...{ onMouseEnter, onMouseLeave, highlight }}
              >
                {columnIds.map((id) => (
                  <td key={id} className='align-top'>
                    {isNullOrUndefined(data[id]) ? '' : data[id]}
                  </td>
                ))}
              </Row>
            );
          } else if (isAction) {
            const { onClick } = row as RowDataAction;
            return (
              <Row
                key={idx}
                type={type}
                hoverable={true}
                clickable={true}
                onClick={onClick}
                {...{ onMouseEnter, onMouseLeave, highlight }}
              >
                {columnIds.map((id) => (
                  <td key={id} className='align-top'>
                    {isNullOrUndefined(data[id]) ? '' : data[id]}
                  </td>
                ))}
              </Row>
            );
          } else if (isLink) {
            const { linkTo } = row as RowDataLink;
            return (
              <Row
                type={type}
                key={idx}
                hoverable={true}
                clickable={true}
                padCells={false}
                {...{ onMouseEnter, onMouseLeave, highlight }}
              >
                {columnIds.map((id) => (
                  <td key={id} className='align-top'>
                    <Link key={idx} href={linkTo} passHref>
                      <TableLink>
                        {isNullOrUndefined(data[id]) ? '' : data[id]}
                      </TableLink>
                    </Link>
                  </td>
                ))}
              </Row>
            );
          }
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
