import { ChevronDownIcon } from '@heroicons/react/outline';
import { Icon } from '@kargo/ui';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import type Option from './types';

type Props = {
  disabled?: boolean;
  options: Option[];
  placeholder?: string;
  size?: string;
  width?: number;
  value?: Option | string;
  error?: any;
  onChange: (opt: Option) => void;
};

const Container = styled.div<Pick<Props, 'disabled' | 'width'>>`
  ${({ theme: { sizing }, disabled, width }) => `
    position: relative;
    height: 40px;
    cursor: pointer;
    border-radius: ${sizing.scale300};
    width: ${width ? `${width}px` : '100%'};
    min-width: auto;
    ${
      disabled &&
      `
        opacity: 0.5;
        cursor: not-allowed;
    `
    }
  `}
`;

const Wrapper = styled.div<Pick<Props, 'error'>>`
  ${({ theme: { sizing }, error }) => `
    position: absolute;
    background-color: white;
    border-radius: ${sizing.scale300};
    width: 100%;
    border: ${error ? `1px solid tomato` : ''};
  `}
`;

const Select = styled.div<Pick<Props, 'size'>>`
  ${({ theme: { sizing }, size }) => `
    display: flex;
    width: 100%;
    background-color: white;
    align-items: center;
    border-radius: ${sizing.scale300};
    justify-content: center;

    padding: ${
      size === 'large'
        ? `${sizing.scale600} ${sizing.scale900}`
        : sizing.scale400
    };
  `}
`;

const ICON_SIZE = '16px';
const PlaceHolder = styled.div`
  width: calc(100% - ${ICON_SIZE});
  white-space: nowrap;
`;

const Options = styled.div`
  ${({ theme: { sizing } }) => `
    position: absolute;
    top: 0px;
    width: 100%;
    padding: 40px 0 0 0;
    z-index: 1;
    border-radius: ${sizing.scale300};
    box-shadow: 0 ${sizing.scale200} ${sizing.scale400} 0 rgb(0 0 0 / 20%);
  `}
`;

const Item = styled.div<{ selected: boolean }>`
  ${({ theme: { colors, sizing }, selected }) => `
    width: 100%;
    padding: 5px 10px;
    background-color: ${selected ? colors.gray200 : 'white'};

    :hover {
      background-color: ${colors.gray100};
    }

    :last-of-type {
      border-radius: ${sizing.scale300};
    }
  `}
`;

const Dropdown = ({
  disabled,
  options,
  placeholder = '',
  size = 'medium',
  width,
  value,
  error,
  onChange,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, toggleOption] = useState(false);

  const checkOutsideClick = (e: MouseEvent) => {
    if (
      isDropdownOpen &&
      ref.current &&
      !ref.current.contains(e.target as Node)
    ) {
      toggleOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick);
    };
  });

  const selectedValue = options.find((item: Option) => item.id === value);

  return (
    <Container
      width={width}
      onClick={disabled ? () => {} : () => toggleOption(!isDropdownOpen)}
      ref={ref}
      disabled={disabled}
    >
      <Wrapper error={error}>
        <Select size={size}>
          <PlaceHolder>
            {selectedValue ? selectedValue.text : placeholder}
          </PlaceHolder>
          <Icon size={ICON_SIZE} icon={ChevronDownIcon} />
        </Select>

        {isDropdownOpen && (
          <Options>
            {options.map((option: Option) => {
              return (
                <Item
                  onClick={() => {
                    onChange(option);
                  }}
                  key={option.id}
                  selected={
                    selectedValue ? selectedValue.id === option.id : false
                  }
                >
                  {option.text}
                </Item>
              );
            })}
          </Options>
        )}
      </Wrapper>
    </Container>
  );
};

export default Dropdown;
