import styled from '@emotion/styled';
import type { ChangeEvent, InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import InputMask from 'react-input-mask';

type Variant = 'rounded' | 'default';
type Kind = 'small' | 'medium' | 'large';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  kind?: Kind;
  variant?: Variant;
  endEnhancer?: JSX.Element | string;
  startEnhancer?: JSX.Element | string;
  mask?: string | Array<string | RegExp>;
  onlyAccept?: RegExp;
  error?: any;
}

const InputContainer = styled.div<
  Pick<Props, 'variant' | 'error' | 'disabled'>
>`
  ${({ theme: { colors }, variant, error, disabled }) => `
    align-items: center;
    border-radius: ${variant === 'rounded' ? '30px' : '5px'};
    border: 1px solid ${
      disabled ? colors.gray100 : error ? 'tomato' : colors.inputBorder
    };
    color: ${colors.inputFontActive};
    display: flex;

    &:focus-within {
      border-color: ${error ? 'tomato' : colors.inputBorderActive};
    }
  `}
`;

const Enhancer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const Input = styled(InputMask)<{
  kind: string;
  disabled?: boolean;
}>`
  ${({ theme: { colors, sizing }, kind, disabled }) => `
    height: 100%;
    width: 100%;
    color: ${colors.inputFont};
    padding: ${
      kind === 'large'
        ? `${sizing.scale600} ${sizing.scale900}`
        : sizing.scale400
    };

  background: ${disabled ? colors.gray100 : colors.inputFill};
  border-radius: 5px;

  :disabled {
    background: ${colors.gray100};
  }

  &:focus {
    color: ${colors.inputFontActive};
  }

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }
  `}
`;

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      inputClassName = '',
      className = '',
      style = {},
      value = '',
      onChange = () => {},
      variant = 'default',
      kind = 'medium',
      startEnhancer,
      endEnhancer,
      mask = '',
      onlyAccept,
      error,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const testRegex = (reg: RegExp, value: string) => reg.test(value);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (onlyAccept && val && !testRegex(onlyAccept, val)) return;
      onChange(e);
    };

    return (
      <InputContainer
        variant={variant}
        style={style}
        className={className}
        error={error}
        disabled={disabled}
      >
        {startEnhancer && <Enhancer>{startEnhancer}</Enhancer>}
        <Input
          className={inputClassName}
          inputRef={(el: HTMLInputElement) => {
            if (typeof ref === 'function') {
              ref(el);
            } else if (ref) {
              ref.current = el;
            }
          }}
          mask={mask}
          value={value}
          kind={kind}
          onChange={!mask && onlyAccept ? handleChange : onChange}
          disabled={disabled}
          {...rest}
        />
        {endEnhancer && <Enhancer>{endEnhancer}</Enhancer>}
      </InputContainer>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;
