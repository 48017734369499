import { Icon } from '@components/kargo-ui';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ChartBarIcon,
  ClipboardListIcon,
  CogIcon,
  ExclamationCircleIcon,
  OfficeBuildingIcon as FacilityIcon,
  TruckIcon,
} from '@heroicons/react/outline';
import { useFeatureFlagEnabled } from '@kargo/context';
import { useRouter } from 'next/router';
import { NavItem } from '../../navigation/nav-item';

type StyledNavLabelProps = {
  isSelected: boolean;
};

const StyledNavLabel = styled.p<StyledNavLabelProps>`
  display: flex;
  margin-left: 14px;
  font-weight: ${(p) => (p.isSelected ? 600 : 400)};
`;

type StyledNavLinkProps = {
  isSelected: boolean;
};

const StyledNavLink = styled.a<StyledNavLinkProps>`
  ${({ theme: { colors }, isSelected }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s ease-out;

    background-color: ${
      isSelected
        ? colors.sidebarSelectedBackground
        : colors.sidebarUnselectedBackground
    };

    color: ${
      isSelected ? colors.sidebarSelectedText : colors.sidebarUnselectedText
    };

    padding: 12px 4px 12px 24px;

    cursor: pointer;
    font-size: 14px;

    :hover {
      color: ${colors.white};
    }

    :focus-visible {
      outline: 1px auto ${colors.white};
    }
  `}
`;

type DashboardLinkType = {
  label: string;
  href: string;
  iconComponent?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

type Props = {
  hasBusinessSettingsPermissions: boolean;
};

const DashboardNav = ({
  hasBusinessSettingsPermissions,
}: Props): JSX.Element => {
  const { route } = useRouter();
  const { colors } = useTheme();
  const isAnalyticsPageEnabled = useFeatureFlagEnabled({
    featureId: 'analytics_page_20220505',
    enabledVariants: ['enabled'],
  });
  const allowExceptionsAccess = useFeatureFlagEnabled({
    featureId: 'allow_exceptions_route_20220707',
    enabledVariants: ['enabled'],
  });

  const dashboardLinks: DashboardLinkType[] = [
    {
      label: 'Shipments',
      href: '/shipments',
      iconComponent: TruckIcon,
    },
    {
      label: 'Orders',
      href: '/orders',
      iconComponent: ClipboardListIcon,
    },
  ];

  if (allowExceptionsAccess) {
    dashboardLinks.push({
      label: 'Exceptions',
      href: '/exceptions',
      iconComponent: ExclamationCircleIcon,
    });
  }

  dashboardLinks.push({
    label: 'Facility',
    href: '/facility',
    iconComponent: FacilityIcon,
  });

  if (isAnalyticsPageEnabled) {
    dashboardLinks.push({
      label: 'Analytics',
      href: '/analytics',
      iconComponent: ChartBarIcon,
    });
  }

  if (hasBusinessSettingsPermissions) {
    dashboardLinks.push({
      label: 'Organization Settings',
      href: '/organization',
      iconComponent: CogIcon,
    });
  }

  return (
    <>
      {dashboardLinks.map(({ label, href, iconComponent }) => {
        const isSelected =
          href === '/' || href === '/admin'
            ? href === route
            : route.includes(href);

        return (
          <NavItem key={label} href={href}>
            <StyledNavLink isSelected={isSelected}>
              {iconComponent && (
                <Icon
                  color={isSelected ? colors.white : colors.gray500}
                  strokeWidth={isSelected ? 2 : 1.5}
                  icon={iconComponent}
                />
              )}

              <StyledNavLabel isSelected={isSelected}>{label}</StyledNavLabel>
            </StyledNavLink>
          </NavItem>
        );
      })}
    </>
  );
};

export { DashboardNav };
