export const CALENDAR_FORMAT = {
  sameDay: '[Today] [at] h:mm a',
  nextDay: 'M/DD/YY [at] h:mm a',
  nextWeek: 'M/DD/YY [at] h:mm a',
  lastDay: 'M/DD/YY [at] h:mm a',
  lastWeek: 'M/DD/YY [at] h:mm a',
  sameElse: 'M/DD/YY [at] h:mm a',
};

export const DAY_FORMAT = {
  sameDay: '[Today]',
  nextDay: 'MMM D',
  nextWeek: 'MMM D',
  lastDay: 'MMM D',
  lastWeek: 'MMM D',
  sameElse: 'MMM D',
};
