import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import type { Kind, OverrideColors, Size, Variant } from './types';

type Type = 'submit' | 'button';

type Props = {
  size?: Size;
  style?: {};
  kind?: Kind;
  variant?: Variant | Array<Variant>;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string | undefined;
  onClick?: Function | undefined;
  type?: Type;
  overrideColors?: OverrideColors;
  asDiv?: boolean;
};

const getBackground = ({
  theme,
  kind,
  disabled,
  hover = false,
  variant,
  overrideColors,
}: {
  theme: any;
  kind: Kind;
  disabled: boolean;
  hover: boolean;
  variant: Array<Variant>;
  overrideColors?: OverrideColors;
}) => {
  if (variant.includes('link')) return 'inherit';
  if (disabled)
    return (
      overrideColors?.background?.disabled || theme.colors.buttonDisabledFill
    );
  if (variant.includes('outline') && !overrideColors?.background)
    return 'transparent';

  const colors = {
    CTA: hover ? theme.colors.buttonCTAHover : theme.colors.buttonCTAFill,
    primary: hover
      ? theme.colors.buttonPrimaryHover
      : theme.colors.buttonPrimaryFill,
    secondary: hover
      ? theme.colors.buttonSecondaryHover
      : theme.colors.buttonSecondaryFill,
    minimal: hover
      ? theme.colors.buttonMinimalHover
      : theme.colors.buttonMinimalFill,
  };

  return hover
    ? overrideColors?.background?.hover || colors[kind]
    : overrideColors?.background?.default || colors[kind];
};

const getColor = ({
  theme,
  kind,
  variant,
  disabled,
  hover = false,
  overrideColors,
}: {
  theme: any;
  kind: Kind;
  variant: Array<Variant>;
  disabled: boolean;
  hover?: boolean;
  overrideColors?: OverrideColors;
}) => {
  if (disabled)
    return overrideColors?.font?.disabled || theme.colors.buttonDisabledText;

  if (variant.includes('link'))
    return hover
      ? overrideColors?.font?.hover || theme.colors.buttonLinkTextHover
      : overrideColors?.font?.default || theme.colors.buttonLinkText;

  if (variant.includes('outline')) {
    const outlineColors = {
      primary: hover
        ? theme.colors.buttonPrimaryHoverText
        : theme.colors.buttonPrimaryText,
      secondary: theme.colors.buttonSecondaryText,
      minimal: theme.colors.contentPrimary,
      CTA: theme.colors.buttonCTAFill,
    };

    return hover
      ? overrideColors?.font?.hover || outlineColors[kind]
      : overrideColors?.font?.default || outlineColors[kind];
  }

  const colors = {
    primary: hover
      ? theme.colors.buttonPrimaryHoverText
      : theme.colors.buttonPrimaryText,
    secondary: theme.colors.buttonSecondaryText,
    minimal: theme.colors.contentPrimary,
    CTA: theme.colors.buttonCTAText,
  };

  return hover
    ? overrideColors?.font?.hover || colors[kind]
    : overrideColors?.font?.default || colors[kind];
};

const getPadding = ({
  theme,
  size,
  variant,
}: {
  theme: any;
  size: Size;
  variant: Array<Variant>;
}) => {
  if (variant.includes('link')) return '0';
  return {
    default: `${theme.sizing.scale300} ${theme.sizing.scale700}`,
    compact: `${theme.sizing.scale80} ${theme.sizing.scale80}`,
    xsmall: `${theme.sizing.scale100} ${theme.sizing.scale100}`,
    small: `${theme.sizing.scale300} ${theme.sizing.scale300}`,
    medium: `${theme.sizing.scale400} ${theme.sizing.scale500}`,
    large: `${theme.sizing.scale600} ${theme.sizing.scale900}`,
  }[size];
};

const getRadius = (theme: any, size: Size, variant: Array<Variant>) => {
  if (variant.includes('oval')) return '50%';
  if (!variant.includes('rounded')) return theme.sizing.scale300;
  if (size === 'large') return theme.sizing.scale1500;
  if (size === 'medium') return theme.sizing.scale1200;
  if (size === 'small') return theme.sizing.scale900;
  if (size === 'xsmall') return theme.sizing.scale100;
  return theme.sizing.scale900;
};

type ButtonProps = {
  kind: Kind;
  size: Size;
  variant: Array<Variant>;
  disabled: boolean;
  overrideColors?: OverrideColors;
};

const StyledButton = styled.button<ButtonProps>`
  ${({ theme, size, variant, overrideColors, kind, disabled }) => `
    margin: 0;
    display: inline-block;

    border-radius: ${getRadius(theme, size, variant)};

    font-size: ${
      size === 'small' ? theme.sizing.scale600 : theme.sizing.scale700
    };

    background: ${getBackground({
      overrideColors,
      hover: false,
      theme,
      kind,
      disabled,
      variant,
    })};

    color: ${getColor({
      overrideColors,
      theme,
      kind,
      disabled,
      variant,
      hover: false,
    })};


    padding: ${getPadding({ theme, size, variant })};

    line-height: ${variant.includes('link') ? 0 : 'auto'};

    border: 2px solid ${
      variant.includes('outline')
        ? getColor({
            overrideColors,
            theme,
            kind,
            disabled,
            variant,
            hover: false,
          })
        : 'transparent'
    };

    &:hover {
      background: ${getBackground({
        overrideColors,
        theme,
        kind,
        disabled,
        hover: true,
        variant,
      })};

      border: ${
        variant.includes('outline')
          ? `${theme.sizing.scale100} solid ${getColor({
              overrideColors,
              theme,
              kind,
              disabled,
              variant,
              hover: true,
            })}`
          : `${theme.sizing.scale100} solid transparent`
      };

      color: ${getColor({
        theme,
        hover: true,
        variant,
        kind,
        disabled,
        overrideColors,
      })}
    }

  `}
`;

const Button = ({
  size = 'default',
  style = {},
  children,
  kind = 'primary',
  variant = 'default',
  disabled = false,
  className = '',
  onClick,
  type = 'button',
  overrideColors,
  asDiv = false,
}: Props) => {
  const clicked = useCallback(
    (...inputs: any) => {
      if (!disabled && onClick) onClick(...inputs);
    },
    [onClick, disabled],
  );

  const variantArray = useMemo(() => {
    return typeof variant === 'string' ? [variant] : variant || [];
  }, [variant]);

  return (
    <StyledButton
      as={asDiv ? 'div' : 'button'}
      overrideColors={overrideColors}
      className={className}
      style={style}
      kind={kind}
      variant={variantArray}
      size={size}
      disabled={disabled}
      onClick={clicked}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
