import { Popover as HeadlessPopover } from '@headlessui/react';

type Props = {
  button: React.ReactNode;
  children: React.ReactNode;
  style?: { [key: string]: any };
  buttonStyle?: { [key: string]: any };
  className?: string;
  xAlign?: 'left' | 'right';
};

const Popover = ({
  button,
  children,
  style = {},
  buttonStyle = {},
  className = '',
  xAlign = 'left',
}: Props) => {
  return (
    <HeadlessPopover style={style} className={`relative ${className}`}>
      <HeadlessPopover.Button style={buttonStyle}>
        {button}
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        className={`absolute mt-2 bg-white z-10 ${
          xAlign === 'right' ? 'right-0' : 'left-0'
        }`}
      >
        {children}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  );
};

export default Popover;
