import { AuthenticatedPage, ApolloContainer, Logo } from '@components';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.contentSecondary};
  width: 100%;
  flex-direction: column;
  flex: 0 0 100%;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.sidebarBackground};
  flex: ${({ theme }) => `0 0 ${theme.sizing.scale2400}`};
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LogoContainer = styled.a`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 10px;
  width: SIDE_NAV_WIDTH;
`;

const PageContent = styled.div`
  color: ${({ theme }) => theme.colors.contentSecondary};
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
`;

type Props = { children: React.ReactNode };

const ViewerLayout = ({ children }: Props) => {
  return (
    <AuthenticatedPage>
      <Container>
        <Header>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Header>
        <Content>
          <PageContent>
            <ApolloContainer>{children}</ApolloContainer>
          </PageContent>
        </Content>
      </Container>
    </AuthenticatedPage>
  );
};

export default ViewerLayout;
