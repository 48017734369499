import Link from 'next/link';

type NavIconProps = {
  href: string;
  children: React.ReactNode;
};

const NavItem = ({ href, children }: NavIconProps) => {
  return (
    <Link href={href} passHref>
      {children}
    </Link>
  );
};

export { NavItem };
