import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { KargoTheme } from '@kargo/types/theming';
import type { AvatarProps } from '@mui/material/Avatar';
import Avatar from '@mui/material/Avatar';

type AvatarColorMap = {
  color: string;
  backgroundColor: string;
};

function getAvatarColorMap({ colors }: KargoTheme): AvatarColorMap[] {
  return [
    { color: colors.black, backgroundColor: colors.portica },
    {
      color: colors.white,
      backgroundColor: colors.midnight,
    },
    { color: colors.black, backgroundColor: colors.saffron },
    { color: colors.white, backgroundColor: colors.danube },
    { color: colors.white, backgroundColor: colors.tradewind },
    { color: colors.black, backgroundColor: colors.conifer },
    { color: colors.white, backgroundColor: colors.jade },
    { color: colors.white, backgroundColor: colors.persian },
    { color: colors.black, backgroundColor: colors.jaffa },
  ];
}

// Code for this color generation inspired by MUI docs: https://mui.com/material-ui/react-avatar/#letter-avatars
function stringToColorIdx(string: string, maxBound: number): number {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return Math.abs(hash) % maxBound;
}

type StyledAvatarBubbleProps = {
  avatarColors: AvatarColorMap | null;
};

const StyledAvatarBubble = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'avatarColors',
})<StyledAvatarBubbleProps>`
  ${(p) =>
    p.avatarColors &&
    `
    color: ${p.avatarColors.color};
    background-color: ${p.avatarColors.backgroundColor};
  `}
`;

type Props = AvatarProps & {
  avatarHash?: string;
  children?: React.ReactNode;
};

const KargoAvatarBubble = ({
  avatarHash,
  children,
  ...props
}: Props): JSX.Element => {
  const theme = useTheme();

  const avatarColorMap = getAvatarColorMap(theme);
  const avatarColors = avatarHash
    ? avatarColorMap[stringToColorIdx(avatarHash, avatarColorMap.length)]
    : null;

  return (
    <StyledAvatarBubble avatarColors={avatarColors} {...props}>
      {children}
    </StyledAvatarBubble>
  );
};

export { KargoAvatarBubble };
