import styled from '@emotion/styled';

type Props = any;

const Input = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => theme.sizing.scale400};
  background: ${({ theme }) => theme.colors.inputFill};
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  color: ${({ theme }) => theme.colors.inputFont};

  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.inputFillActive};
    border: 1px solid ${({ theme }) => theme.colors.inputBorderActive};
    color: ${({ theme }) => theme.colors.inputFontActive};
  }
`;

export const TextArea = ({
  style = {},
  className = '',
  value = '',
  onChange = () => {},
  rows = 3,
}: Props) => {
  return (
    <Input
      rows={rows}
      className={className}
      style={style}
      value={value}
      onChange={onChange}
    />
  );
};

export default TextArea;
