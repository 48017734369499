import { css } from '@emotion/react';
import { LoadingKeyframes } from '.';
import type { ISettings } from './settings';

const getSkeletonStyles = (
  {
    backgroundColor,
    waveColor,
    waveDirection,
    waveSpeed,
    waveEase,
    waveCount,
  }: ISettings,
  colors: { [key: string]: string },
) => css`
  position: relative;
  background-color: ${backgroundColor || colors.gray200};
  overflow: hidden;

  &:after {
    display: block;
    position: absolute;
    transform: translateX(-100%);
    left: 0;
    right: 0;

    content: '';

    height: 100%;
    opacity: 0.8;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      ${backgroundColor || colors.gray200},
      ${waveColor || colors.white},
      ${backgroundColor || colors.gray200}
    );

    animation-name: ${LoadingKeyframes};
    animation-direction: ${waveDirection};
    animation-duration: ${waveSpeed}s;
    animation-timing-function: ${waveEase};
    animation-iteration-count: ${waveCount};
  }
`;

export default getSkeletonStyles;
