import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ChevronLeftIcon as PreviousIcon,
  ChevronRightIcon as NextIcon,
} from '@heroicons/react/outline';
import { Button, Icon } from '@kargo/ui';
import { useMemo } from 'react';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: ${({ theme }) => `0 ${theme.sizing.scale300}`};
  align-items: center;
`;

const Legend = styled.div<{ size: Size }>`
  box-sizing: border-box;
  margin: ${({ theme }) => `0 ${theme.sizing.scale500}`};
  font-size: ${({ theme }) => theme.sizing.scale600};
  font-weight: 700;
`;

const NavButtons = styled.div`
  display: flex;
  align-items: center;
`;

type Size = 'xsmall' | 'small' | 'medium' | 'large';

type Props = {
  currentPage?: number;
  totalPages: number;
  setPage: (page: number, event?: React.MouseEvent) => void;
  size?: Size;
};

const Pagination = ({
  totalPages,
  currentPage,
  setPage,
  size = 'small',
}: Props) => {
  const theme = useTheme();

  const [previousFn, nextFn] = useMemo(() => {
    if (typeof currentPage !== 'number') return [undefined, undefined];

    const next = currentPage + 1;
    const previous = currentPage - 1;

    return [
      previous > 0
        ? (e?: React.MouseEvent) => {
            setPage(previous, e);
          }
        : undefined,
      next <= totalPages
        ? (e?: React.MouseEvent) => {
            setPage(next, e);
          }
        : undefined,
    ];
  }, [setPage, totalPages, currentPage]);

  const overrideColors = {
    background: {
      disabled: 'transparent',
      default: 'transparent',
      hover: 'transparent',
    },
    font: {
      default: theme.colors.paginationButtonOutline,
      hover: theme.colors.paginationButtonOutlineHover,
      disabled: theme.colors.paginationButtonOutlineDisabled,
    },
  };

  return (
    <Container>
      <NavButtons>
        <Button
          size={size}
          style={{ marginRight: theme.sizing.scale200 }}
          disabled={previousFn === undefined}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            previousFn && previousFn(e);
          }}
          kind='primary'
          variant={['outline', 'oval']}
          overrideColors={overrideColors}
        >
          <Icon icon={PreviousIcon} size={theme.sizing.scale1000} />
        </Button>

        <Legend size={size}>
          {typeof currentPage === 'number' ? currentPage : 'N/A'}/{totalPages}
        </Legend>

        <Button
          disabled={nextFn === undefined}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            nextFn && nextFn(e);
          }}
          size={size}
          kind='primary'
          variant={['outline', 'oval']}
          overrideColors={overrideColors}
        >
          <Icon icon={NextIcon} size={theme.sizing.scale1000} />
        </Button>
      </NavButtons>
    </Container>
  );
};

export default Pagination;
