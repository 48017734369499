import styled from '@emotion/styled';
import type { DrawerProps } from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';

type StyledDrawerProps = DrawerProps & {
  headerOffset?: string;
};

const StyledDrawer = styled(
  (props: StyledDrawerProps) => (
    <Drawer
      {...props}
      classes={{
        ...props.classes,
        paper: `kargo-drawer-paper ${props.classes?.paper ?? ''}`,
      }}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'headerOffset',
  },
)`
  & .kargo-drawer-paper {
    margin-top: ${(p) => p.headerOffset ?? 0};
  }
`;

type Props = DrawerProps & {
  headerOffset?: string;
  children: React.ReactNode;
};

const KargoDrawer = ({
  headerOffset,
  children,
  ...props
}: Props): JSX.Element => {
  return (
    <StyledDrawer headerOffset={headerOffset} {...props}>
      {children}
    </StyledDrawer>
  );
};

export { KargoDrawer };
