import styled from '@emotion/styled';
import { XIcon } from '@heroicons/react/outline';
import { Button, Icon, Layer } from '@kargo/ui';
import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import type { LayerProps } from '../layer/layer';

type Props = {
  children: React.ReactNode;
  title?: React.ReactNode;
  closeOnEscape?: boolean;
  backgroundClose?: boolean;
  onClose: () => void;
};

const Container = styled((props: LayerProps) => <Layer {...props} />)<{
  onMouseDown: (event: MouseEvent) => void;
  onMouseUp: (event: MouseEvent) => void;
}>`
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.sizing.scale1000};
  margin-bottom: ${({ theme }) => theme.sizing.scale500};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: stretch;
`;

const Title = styled.div`
  padding: ${({ theme }) => theme.sizing.scale800};
`;

const CloseButton = styled(Button)`
  border-left: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-radius: 0;
  min-height: 100%;
  align-items: center;
  display: flex;
  border-top-right-radius: ${({ theme }) => theme.sizing.scale300};
`;

const ContentContainer = styled.div`
  max-width: 80%;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: ${({ theme }) => theme.sizing.scale400};
  border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  box-shadow: 0px 15px 20px 5px rgba(0, 0, 0, 0.4);
`;

const Modal = ({
  children,
  onClose,
  closeOnEscape = true,
  backgroundClose = true,
}: Props) => {
  useEffect(() => {
    if (closeOnEscape !== true) return;
    const onEscape = (e: any) => {
      e.stopPropagation();
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', onEscape);
    return () => {
      window.removeEventListener('keydown', onEscape);
    };
  }, [closeOnEscape]);

  const closing = useRef(false);

  const onBackgroundMouseDown = useCallback((e: any) => {
    if (e.target !== e.currentTarget) return;
    e.stopPropagation();
    closing.current = true;

    const mouseUp = () => {
      closing.current = false;
      window.removeEventListener('mouseup', mouseUp);
    };
    window.addEventListener('mouseup', mouseUp);
  }, []);

  const onBackgroundClose = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (e.target !== e.currentTarget) return;
      if (closing.current === true && backgroundClose === true && onClose)
        onClose();
    },
    [onClose, backgroundClose],
  );

  const childrenWithProps = useMemo(() => {
    return Children.map(children, (child: any) => {
      if (child.type === ModalTitle) {
        return cloneElement(child, { ...child.props, onClose });
      } else {
        return child;
      }
    });
  }, [children]);

  return (
    <Container
      onMouseDown={onBackgroundMouseDown}
      onMouseUp={onBackgroundClose}
    >
      <ContentContainer onClick={(e) => e.stopPropagation()}>
        {childrenWithProps}
      </ContentContainer>
    </Container>
  );
};

const ModalTitle = ({
  children,
  onClose,
  hasClose = false,
}: {
  children: React.ReactNode;
  onClose?: () => void;
  hasClose?: boolean;
}) => {
  return (
    <Header>
      <Title>{children}</Title>

      {hasClose && (
        <CloseButton kind='minimal' onClick={onClose}>
          <Icon size='32px' icon={XIcon} />
        </CloseButton>
      )}
    </Header>
  );
};

export { Modal, ModalTitle };
