import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const APP_ID = 'w47b2nzc';

export const useIntercom = () => {
  const { user } = useAuth0();
  const router = useRouter();

  useEffect(() => {
    // @ts-ignore
    // prettier-ignore
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }, []);

  useEffect(() => {
    // @ts-ignore
    const intercom = window.Intercom;
    if (!intercom) return;
    if (user)
      intercom('boot', {
        app_id: APP_ID,
        name: user.name,
        email: user.email,
        user_hash: user['http://hash'],
      });
    else {
      intercom('boot', { app_id: APP_ID });
    }

    return () => {
      intercom('shutdown');
    };
  }, [user]);

  useEffect(() => {
    // @ts-ignore
    const intercom = window.Intercom;

    const updateIntercom = () => {
      if (!intercom) return;
      return () => intercom('update');
    };

    router.events.on('routeChangeComplete', updateIntercom);

    return () => {
      router.events.off('routeChangeComplete', updateIntercom);
    };
  }, [router]);
};
