import { ClockIcon, DocumentAddIcon } from '@heroicons/react/outline';
import { Alert, Icon } from '@kargo/ui';
import type { ChangeEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import type HandleFileUploadFunction from './types';
import Stack from '@mui/material/Stack';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from '@mui/material/Button';

const MB_TO_BYTES = 1000000;

type Props = {
  handleFileUpload: HandleFileUploadFunction;
  onFilesUploaded?: () => void | undefined;
  maxSizeMb?: number;
  accept: string[];
  filesPerUploadLimit?: number;
};

const FileUploadContainer = styled.div`
  ${({ theme: { sizing } }) => `
    margin-top: ${sizing.scale800};
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 40px 120px 40px;
  `};
`;

const FileUploadComponent = styled.div<{
  dragging: boolean;
}>`
  ${({ theme: { sizing, colors }, dragging }) => `
    background: ${dragging ? '#F0F4F6' : 'transparent'};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${dragging ? `2px solid ${colors.shipping}` : ''};
    min-height: 300px;
  `};
`;

const FileUploadInfo = styled.div`
  ${({ theme: { sizing } }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
    text-align: center;
    font-size: ${sizing.scale700};
    svg path {
      stroke-width: 1px;
    }
  `}
`;

const FileUploadText = styled.div`
  ${({ theme: { sizing } }) => `
    margin-top: ${sizing.scale500};
  `}
`;

const FileUploadErrorText = styled.div`
  ${({ theme: { colors } }) => `
    color: ${colors.red400}
  `};
`;

const UploadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledFileUploadContainer = styled.div`
  background-color: ${(p) => p.theme.colors.gray50};
  border-top: 1px solid ${(p) => p.theme.colors.gray300};
  bottom: 0px;
  padding: 20px;
  position: fixed;
  width: 100%;
`;

const StyledUploadButton = styled(Button)`
  ${({ theme: { palette, colors } }) => `
    border: 2px solid ${colors.shipping};
    color: ${colors.shipping};

    &:hover {
      border: 2px solid ${palette?.primary.dark};
      background-color: ${palette?.primary.dark};
      color: ${palette?.primary.light};
    }
  `}
  border-radius: 25px;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 10px 20px;
  text-transform: none;
  transition: all 0.2s ease-in-out;
`;

const FileUpload = ({
  handleFileUpload,
  accept,
  maxSizeMb = 10,
  filesPerUploadLimit = 5,
  onFilesUploaded,
}: Props) => {
  const theme: any = useTheme();
  const dropRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState('');
  const [dragCounter, setDragCounter] = useState(0);

  const [files, setFiles] = useState<File[]>([]);
  const [fileListSize, setFileListSize] = useState(0);
  const [filesUploadedCount, setFilesUploadedCount] = useState(0);
  const [filesNotUploaded, setFilesNotUploaded] = useState<string[]>([]);

  const uploadFiles = (files: FileList | undefined) => {
    if (files) {
      if (files.length > filesPerUploadLimit) {
        setError(
          `Maximum number of files exceeded. Maximum limit is ${filesPerUploadLimit} files per upload.`,
        );
      } else {
        const filesArray = Array.from(files);
        setFileListSize(files.length);
        setFiles(filesArray);
      }
    }
  };

  const canUploadFile = (file: File) => {
    return checkFileExtension(file.name) && checkMaxFileSize(file.size);
  };

  const checkMaxFileSize = (maxSize: number) => {
    return maxSize <= maxSizeMb * MB_TO_BYTES;
  };

  const checkFileExtension = (fileName: string | undefined): boolean => {
    if (!fileName) return false;
    const fileExtension = fileName.split('.').slice(-1)[0];
    return accept.includes(fileExtension);
  };

  const onFileUploaded = (result: boolean, file: File) => {
    if (result) {
      setFilesUploadedCount((prev) => prev + 1);
    } else {
      setFilesNotUploaded((prev) => prev.concat([file.name]));
      setFileListSize((prev) => prev - 1);
    }
  };

  const openFileDialog = () => {
    setFilesNotUploaded([]);
    setError('');
    inputFileRef.current?.click();
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setFilesNotUploaded([]);
    setError('');
    setDragCounter((prevCounter) => prevCounter + 1);
    if (e?.dataTransfer && e?.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prevCounter) => prevCounter - 1);
  };

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
    setDragCounter(0);
    const files = e.dataTransfer?.files;
    uploadFiles(files);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = e.target.files;
    uploadFiles(files);
    e.target.value = '';
  };

  const getErrorUploadMessage = () => {
    return (
      <>
        <Alert type='warning'>
          {`Max file size is ${maxSizeMb}MB and accepted file types are:
          ${accept.join(', ')}`}
        </Alert>
        <Alert type='negative'>{error}</Alert>
      </>
    );
  };

  useEffect(() => {
    if (dragCounter === 0) {
      setDragging(false);
    }
  }, [dragCounter]);

  useEffect(() => {
    const div = dropRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }

    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      files.forEach((file) => {
        canUploadFile(file)
          ? handleFileUpload(file, onFileUploaded)
          : onFileUploaded(false, file);
      });
    }
  }, [files]);

  useEffect(() => {
    if (filesUploadedCount === fileListSize) {
      setFileListSize(0);
      setFilesUploadedCount(0);
      setFiles([]);
      if (onFilesUploaded) onFilesUploaded();
    }
  }, [filesUploadedCount, fileListSize]);

  useEffect(() => {
    if (filesNotUploaded.length > 0) {
      setError(
        `Couldn't upload the following files: ${filesNotUploaded.join(', ')}`,
      );
    }
  }, [filesNotUploaded]);

  const fileUploadContent = () => {
    if (dragging) {
      return (
        <>
          <Icon
            strokeWidth={1}
            size={theme.sizing.scale3000}
            icon={DocumentAddIcon}
          />
          <FileUploadText>Drop files to instantly upload</FileUploadText>
        </>
      );
    }

    if (files.length > 0) {
      return (
        <>
          <Icon
            strokeWidth={1}
            size={theme.sizing.scale3000}
            icon={ClockIcon}
          />
          <FileUploadText>
            Uploading Files: {filesUploadedCount} / {fileListSize}
            {filesNotUploaded.length > 0 && (
              <FileUploadErrorText>
                Files not uploaded: {filesNotUploaded.length}
              </FileUploadErrorText>
            )}
          </FileUploadText>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <FileUploadContainer>
        {error !== '' && getErrorUploadMessage()}
        {files.length > 0 && <UploadingOverlay />}
        <FileUploadComponent ref={dropRef} dragging={dragging}>
          <FileUploadInfo data-testid='drop-zone'>
            {fileUploadContent()}
          </FileUploadInfo>
          <input
            data-testid='upload-file-input'
            ref={inputFileRef}
            type='file'
            multiple
            style={{ display: 'none' }}
            onChange={onInputChange}
          />
        </FileUploadComponent>
      </FileUploadContainer>

      <StyledFileUploadContainer>
        <form>
          <Stack direction='row' alignItems='center' gap={2}>
            <StyledUploadButton
              onClick={openFileDialog}
              className='upload-document'
            >
              <FileUploadIcon /> Upload files
            </StyledUploadButton>
            Or drag to file list
          </Stack>
        </form>
      </StyledFileUploadContainer>
    </>
  );
};

export default FileUpload;
