const Logo = () => (
  <svg
    width='82'
    height='20'
    viewBox='0 0 583 141'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='inline'
  >
    <path
      d='M0 131.815L0 29.6328C0 26.9212 0.885417 24.8184 2.65625 23.3242C4.42708 21.7747 6.36393 21 8.4668 21C10.625 21 12.5895 21.7747 14.3604 23.3242C16.1312 24.8184 17.0166 26.9212 17.0166 29.6328L17.0166 72.2988H36.6064L63.916 39.2617V29.6328C63.916 26.9212 64.8014 24.8184 66.5723 23.3242C68.3431 21.7747 70.2799 21 72.3828 21C74.541 21 76.5055 21.7747 78.2764 23.3242C80.0472 24.8184 80.9326 26.9212 80.9326 29.6328V35.1943C80.9326 40.3962 80.7389 43.7441 80.3516 45.2383C79.7428 47.3411 78.304 49.7207 76.0352 52.377L51.7139 80.7656L76.0352 108.988C78.3594 111.7 79.7428 113.969 80.1855 115.795C80.6836 117.566 80.9326 120.526 80.9326 124.677C80.9326 125.341 80.9326 125.839 80.9326 126.171V131.815C80.9326 134.527 80.0472 136.658 78.2764 138.207C76.5055 139.701 74.541 140.448 72.3828 140.448C70.2799 140.448 68.3431 139.701 66.5723 138.207C64.8014 136.658 63.916 134.527 63.916 131.815L63.916 122.104L36.7725 89.3154H17.0166L17.0166 131.815C17.0166 134.527 16.1312 136.658 14.3604 138.207C12.5895 139.701 10.625 140.448 8.4668 140.448C6.36393 140.448 4.42708 139.701 2.65625 138.207C0.885417 136.658 0 134.527 0 131.815Z'
      fill='white'
    />
    <path
      d='M254.109 131.363V29.141C254.109 26.4837 254.971 24.4077 256.695 22.913C258.418 21.3629 260.42 20.5879 262.7 20.5879H305.568C313.852 20.5879 320.885 23.4943 326.668 29.3071C332.506 35.1199 335.425 42.1506 335.425 50.3992V58.8692C335.425 65.8446 333.257 72.0449 328.92 77.4701C324.583 82.8954 319.078 86.3831 312.406 87.9332L329.42 108.527C332.089 111.738 333.729 114.312 334.341 116.25C334.952 118.187 335.258 121.398 335.258 125.882V131.363C335.258 134.076 334.369 136.207 332.589 137.757C330.81 139.252 328.836 139.999 326.668 139.999C324.555 139.999 322.609 139.252 320.83 137.757C319.051 136.207 318.161 134.076 318.161 131.363V121.979L290.889 88.8466H271.207V131.363C271.207 134.02 270.317 136.096 268.538 137.591C266.758 139.086 264.785 139.833 262.616 139.833C260.503 139.833 258.557 139.086 256.778 137.591C254.999 136.096 254.109 134.02 254.109 131.363ZM271.207 71.8234H305.568C309.07 71.8234 312.073 70.5502 314.575 68.0036C317.077 65.4571 318.328 62.4123 318.328 58.8692V50.3992C318.328 46.9115 317.077 43.9221 314.575 41.4309C312.073 38.8843 309.07 37.611 305.568 37.611H271.207V71.8234Z'
      fill='white'
    />
    <path
      d='M377.652 114.174V46.1641C377.652 39.0781 380.154 33.0439 385.158 28.0615C390.162 23.0791 396.195 20.5879 403.256 20.5879H433.197C440.258 20.5879 446.291 23.0791 451.295 28.0615C456.299 33.0439 458.801 39.0781 458.801 46.1641C458.801 48.8768 457.911 51.0081 456.132 52.5582C454.353 54.0529 452.379 54.8003 450.21 54.8003C448.098 54.8003 446.152 54.0529 444.372 52.5582C442.593 51.0081 441.704 48.8768 441.704 46.1641C441.704 43.839 440.87 41.8461 439.202 40.1853C437.534 38.4691 435.532 37.611 433.197 37.611H403.256C400.976 37.611 398.975 38.4691 397.251 40.1853C395.583 41.8461 394.749 43.839 394.749 46.1641V114.174C394.749 116.554 395.583 118.603 397.251 120.319C398.975 121.979 400.976 122.81 403.256 122.81H433.197C435.476 122.81 437.45 121.979 439.118 120.319C440.842 118.603 441.704 116.554 441.704 114.174V88.8466H428.026C425.301 88.8466 423.161 87.9608 421.604 86.1893C420.103 84.4178 419.352 82.4802 419.352 80.3765C419.352 78.2175 420.103 76.2522 421.604 74.4807C423.161 72.7092 425.301 71.8234 428.026 71.8234H450.294C452.573 71.8234 454.547 72.6815 456.215 74.3977C457.939 76.0585 458.801 78.0237 458.801 80.2935V114.174C458.801 121.26 456.299 127.322 451.295 132.359C446.291 137.342 440.258 139.833 433.197 139.833H403.256C396.195 139.833 390.162 137.342 385.158 132.359C380.154 127.322 377.652 121.26 377.652 114.174Z'
      fill='white'
    />
    <path
      d='M501.194 114.174V46.1641C501.194 39.0781 503.696 33.0439 508.7 28.0615C513.704 23.0791 519.737 20.5879 526.798 20.5879H556.739C563.8 20.5879 569.833 23.0791 574.837 28.0615C579.841 33.0439 582.343 39.0781 582.343 46.1641V114.174C582.343 121.26 579.841 127.322 574.837 132.359C569.833 137.342 563.8 139.833 556.739 139.833H526.798C519.737 139.833 513.704 137.342 508.7 132.359C503.696 127.322 501.194 121.26 501.194 114.174ZM518.291 114.174C518.291 116.554 519.125 118.603 520.794 120.319C522.517 121.979 524.519 122.81 526.798 122.81H556.739C559.019 122.81 560.993 121.979 562.661 120.319C564.384 118.603 565.246 116.554 565.246 114.174V46.1641C565.246 43.839 564.412 41.8461 562.744 40.1853C561.076 38.4691 559.074 37.611 556.739 37.611H526.798C524.519 37.611 522.517 38.4691 520.794 40.1853C519.125 41.8461 518.291 43.839 518.291 46.1641V114.174Z'
      fill='white'
    />
    <path
      d='M168.234 -0.000324754L217.646 139.999L168.234 114.21L118.822 139.999L168.234 -0.000324754Z'
      fill='#FF4800'
    />
  </svg>
);

export default Logo;
