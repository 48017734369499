import { NavItem } from '@components/pages/layouts';
import styled from '@emotion/styled';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import { useRouter } from 'next/router';

type StyledNavLabelProps = {
  isSelected: boolean;
};

const StyledNavLabel = styled.p<StyledNavLabelProps>`
  display: flex;
  margin-left: 14px;
  font-weight: ${(p) => (p.isSelected ? 600 : 400)};
`;

type StyledNavLinkProps = {
  isSelected: boolean;
};

const StyledNavLink = styled.a<StyledNavLinkProps>`
  ${({ theme: { colors }, isSelected }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s ease-out;

    background-color: ${
      isSelected
        ? colors.sidebarSelectedBackground
        : colors.sidebarUnselectedBackground
    };

    color: ${
      isSelected ? colors.sidebarSelectedText : colors.sidebarUnselectedText
    };

    padding: 12px 4px 12px 24px;

    cursor: pointer;
    font-size: 14px;

    :hover {
      color: ${colors.white};
    }

    :focus-visible {
      outline: 1px auto ${colors.white};
    }
  `}
`;

const OPS_TASKS_PATH = '/admin/ops_tasks';

type AdminLinkType = {
  label: string;
  href: string;
  IconComponent?: (props: SvgIconProps) => JSX.Element;
};

const LABELER_LINKS: AdminLinkType[] = [
  {
    href: '/admin/ops_tasks',
    label: 'Operations Tasks',
    IconComponent: NoteAltIcon,
  },
];

const AdminLeftMenuLabeler = (): JSX.Element => {
  const router = useRouter();

  if (!router.pathname.includes(OPS_TASKS_PATH)) {
    router.push(OPS_TASKS_PATH);
  }

  return (
    <>
      {LABELER_LINKS.map(({ label, href, IconComponent }) => {
        const isSelected =
          href === '/' || href === '/admin'
            ? href === router.route
            : router.route.includes(href);

        return (
          <NavItem key={label} href={href}>
            <StyledNavLink isSelected={isSelected}>
              {IconComponent && <IconComponent fontSize='small' />}
              <StyledNavLabel isSelected={isSelected}>{label}</StyledNavLabel>
            </StyledNavLink>
          </NavItem>
        );
      })}
    </>
  );
};

export { AdminLeftMenuLabeler };
