import { Icon } from '@kargo/ui';
import type { SVGProps } from 'react';
import { Children } from 'react';
import styled from '@emotion/styled';

type Fill = 'fixed' | 'intrinsic' | 'default';
type ColorOverrides = {
  font?: string;
  fontHover?: string;
  fontActive?: string;
  bar?: string;
  barHover?: string;
  barActive?: string;
};

const Container = styled.div<{ fixedHeader: boolean }>`
  ${({ fixedHeader }) => `
    display: flex;
    flex-direction: column;
    height: ${fixedHeader ? '100%' : 'auto'};
    width: 100%;
  `}
`;

const HeaderRow = styled.div<{ colorOverrides?: ColorOverrides }>`
  ${({ theme: { sizing, colors }, colorOverrides }) => `
    align-items: center;
    border-bottom: ${sizing.scale200} solid ${
    colorOverrides?.bar || colors.tabsBar
  };
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    flex: 0 0 auto;
  `}
`;

const StartDecorator = styled.div`
  align-items: center;
  display: flex;
  padding-right: 10px;
`;

const EndDecorator = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 10px;
`;

const TabRow = styled.div`
  ${({ theme: { sizing } }) => `
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;
    position: relative;
    bottom: -${sizing.scale200};
  `}
`;

const TabPanels = styled.div<{ fixedHeader: boolean }>`
  ${(p) => `
    height: 100%;
    overflow: ${p.fixedHeader ? 'auto' : 'initial'};
  `}
`;

const TabContainer = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => `
    display: ${isActive ? 'block' : 'none'};
    width: 100%;
    height: 100%;
  `}
`;

const TabSelect = styled.button<{
  isActive: boolean;
  colorOverrides?: ColorOverrides;
  fill: Fill;
}>`
  ${({ theme: { sizing, colors }, fill, colorOverrides, isActive }) => `
    cursor: pointer;
    font-weight: 600;
    padding: 19px;
    position: relative;
    width: ${fill == 'intrinsic' ? '100%' : 'auto'};

    border-bottom: ${sizing.scale200} solid
      ${
        isActive
          ? colorOverrides?.barActive || colors.tabsBarActive
          : 'transparent'
      };

    color: ${
      isActive
        ? colorOverrides?.fontActive || colors.tabsFontActive
        : colorOverrides?.font || colors.gray500
    };

    &:hover {
      color: ${
        isActive
          ? colorOverrides?.fontActive || colors.tabsFontActive
          : colorOverrides?.fontHover || colors.tabsFontHover
      };
      border-bottom: ${sizing.scale200} solid
        ${
          isActive
            ? colorOverrides?.barActive || colors.tabsBarActive
            : colorOverrides?.barHover || colors.tabsBarHover
        };
    }

    svg {
      circle[stroke],
      rect[stroke],
      path[stroke] {
        stroke: ${isActive ? colors.gray900 : colors.gray400};
      }

      circle[fill],
      rect[fill],
      path[fill] {
        fill: ${isActive ? colors.gray900 : colors.gray400};
      }
    }

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &:after {
      content: " ";
      background: #0f0;
    }
  `}
`;

const TabTitleContainer = styled.div<{ colorOverrides?: ColorOverrides }>`
  ${({ theme: { sizing } }) => `
    display: flex;
    gap: 8px;
    font-size: ${sizing.scale600};
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  `}
`;

const TabTitle = ({
  icon,
  title,
  colorOverrides,
}: {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  title: React.ReactNode;
  colorOverrides?: ColorOverrides;
}) => {
  return (
    <TabTitleContainer colorOverrides={colorOverrides}>
      {icon && <Icon icon={icon} size='18px' />}
      <div>{title}</div>
    </TabTitleContainer>
  );
};

const InternalTab = ({
  isActive = false,
  children,
}: {
  isActive: boolean;
  children: React.ReactNode;
}) => {
  return <TabContainer isActive={isActive}>{children}</TabContainer>;
};

const TabSelector = ({
  fill,
  isActive,
  onSelect,
  name,
  icon,
  colorOverrides,
  className,
}: {
  fill: Fill;
  name: React.ReactNode;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onSelect: () => void;
  isActive: boolean;
  colorOverrides?: ColorOverrides;
  className?: string;
}) => {
  return (
    <TabSelect
      fill={fill}
      type='button'
      role='tab-selector'
      isActive={isActive}
      onClick={onSelect}
      colorOverrides={colorOverrides}
      className={className}
    >
      <TabTitle title={name} icon={icon} colorOverrides={colorOverrides} />
    </TabSelect>
  );
};

const Tabs = ({
  children,
  endDecorator,
  startDecorator,
  activeTab,
  setActiveTab,
  fixedHeader = false,
  fill = 'default',
  colorOverrides,
}: {
  children: React.ReactNode;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  fixedHeader?: boolean;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  fill?: Fill;
  colorOverrides?: ColorOverrides;
}) => {
  const tabs = Children.map(children, (child: any, index) => {
    if (!child) return;

    const key = child.key || String(index);
    const onClick = child.props.onClick;

    return (
      <TabSelector
        fill={fill}
        name={child.props.title}
        className={child.props.className}
        icon={child.props.icon}
        onSelect={() => {
          setActiveTab(key);
          onClick && onClick();
        }}
        isActive={key === activeTab}
        colorOverrides={colorOverrides}
      />
    );
  });

  const panels = Children.map(children, (child: any, index) => {
    if (!child) return;

    const key = child.key || String(index);
    return (
      <InternalTab key={key} isActive={key === activeTab}>
        {child.props.children}
      </InternalTab>
    );
  });

  return (
    <Container fixedHeader={fixedHeader}>
      <HeaderRow>
        {startDecorator && <StartDecorator>{startDecorator}</StartDecorator>}
        <TabRow>{tabs}</TabRow>
        {endDecorator && <EndDecorator>{endDecorator}</EndDecorator>}
      </HeaderRow>
      <TabPanels fixedHeader={fixedHeader}>{panels}</TabPanels>
    </Container>
  );
};

// TODO bleung: Fix whole tab file
// eslint-disable-next-line
const Tab = (props: {
  key: string;
  title: React.ReactNode;
  icon?: React.ReactNode | ((props: SVGProps<SVGSVGElement>) => JSX.Element);
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  return <></>;
};

export { Tabs, Tab };
