import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import generatedIntrospection from 'generated/graphql';

export const API_URI =
  process.env.NEXT_PUBLIC_GQL_ENDPOINT || 'https://api.kargo.zone/graphql';

const createApolloClient = ({ token }: { token: string }) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const uploadLink = createUploadLink({
    uri: API_URI,
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
    link: ApolloLink.from([authLink, uploadLink]),
  });
};

export default createApolloClient;
