import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { XIcon as CloseIcon } from '@heroicons/react/outline';
import { Button, FormInput, Icon, Layer, Pagination, Slider } from '@kargo/ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LayerProps } from '../layer/layer';

const ModalLayer = styled((props: LayerProps) => <Layer {...props} />)<{
  onMouseUp: (e: MouseEvent) => void;
  onMouseDown: (e: MouseEvent) => void;
}>`
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const modalSizes = {
  small: '50%',
  medium: '60%',
  large: '80%',
  auto: 'auto',
};
type Size = 'auto' | 'small' | 'medium' | 'large';

const ModalContainer = styled.div<{ size: Size }>`
  border-radius: ${({ theme }) => theme.borders.radius200};
  max-width: ${({ size }) => modalSizes[size]};
  max-height: ${({ size }) => modalSizes[size]};
  overflow: hidden;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
`;

const ModalHeader = styled.div`
  background: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.sizing.scale300};
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  box-sizing: border-box;
  display: flex;
  border-right: ${({ theme }) => theme.sizing.scale400} solid
    ${(p) => p.theme.colors.white};
  border-left: ${({ theme }) => theme.sizing.scale400} solid
    ${(p) => p.theme.colors.white};
`;

const PrimaryImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;
`;

const ModalFooter = styled.div`
  background: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.sizing.scale300};
  justify-content: space-between;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borders.radius200} ${theme.borders.radius200}`};
`;

const Toolbox = styled.div`
  flex: 0 1 300px;
  min-width: 200px;
  box-sizing: border-box;
  padding: 8px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.contentSecondary};
  font-weight: 300;
  overflow: auto;
  padding-right: 16px;
  padding: 10px 30px 0 20px;
`;

const ToolboxHeader = styled.div`
  box-sizing: border-box;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.contentStark};
  font-weight: 500;
`;

type Transform = {
  zoom: number;
  translateX: number;
  translateY: number;
};

type Props = {
  title?: string;
  open: boolean;
  onClose?: () => void;
  backgroundClose?: boolean;
  size?: Size;
  images: Array<string>;
  selectedIndex?: number;
  setSelectedIndex: (index: number | undefined) => void;
  filtersEnabled?: boolean;
};

const useDragAndDrop = (
  imageRef: HTMLImageElement | undefined,
  containerRef: HTMLDivElement | undefined,
  updateZoom: (zoom: number) => void,
  transform: Transform,
  setTransform: (update: Transform) => void,
) => {
  const moving = useRef(false);

  const scrollEvent = useCallback(
    (e: WheelEvent) => {
      e.preventDefault();
      const zoom = Math.min(5, Math.max(transform.zoom + -e.deltaY / 100, 1));
      updateZoom(zoom);
    },
    [transform, imageRef],
  );

  const mouseMoveEvent = useCallback(
    (e: MouseEvent) => {
      if (!imageRef || !containerRef) return;
      if (e.target !== imageRef || moving.current !== true) return;
      const iBox = imageRef.getBoundingClientRect();
      // divide by 2 because the translate origin is center (50%)
      const overflowX = (iBox.width - containerRef.clientWidth) / 2;
      const overflowY = (iBox.height - containerRef.clientHeight) / 2;

      const x = transform.translateX + e.movementX;
      const y = transform.translateY + e.movementY;

      setTransform({
        ...transform,
        translateX: Math.max(-overflowX, Math.min(overflowX, x)),
        translateY: Math.max(-overflowY, Math.min(overflowY, y)),
      });
    },
    [transform, imageRef, containerRef],
  );

  useEffect(() => {
    if (!imageRef || !containerRef) return;

    const mouseDownEvent = (e: MouseEvent) => {
      if (e.target !== imageRef) return;
      e.preventDefault();
      moving.current = true;
    };
    const mouseUpEvent = () => (moving.current = false);

    imageRef.addEventListener('wheel', scrollEvent);
    window.addEventListener('mousedown', mouseDownEvent);
    window.addEventListener('mousemove', mouseMoveEvent);
    window.addEventListener('mouseup', mouseUpEvent);

    return () => {
      imageRef.removeEventListener('wheel', scrollEvent);
      window.removeEventListener('mousedown', mouseDownEvent);
      window.removeEventListener('mousemove', mouseMoveEvent);
      window.removeEventListener('mouseup', mouseUpEvent);
    };
  }, [imageRef, containerRef, transform]);
};

const ImageViewer = ({
  title,
  open,
  onClose,
  backgroundClose = true,
  size = 'large',
  images,
  selectedIndex,
  setSelectedIndex,
  filtersEnabled = false,
}: Props) => {
  const theme = useTheme();
  const image = useMemo(
    () => (typeof selectedIndex === 'number' ? images[selectedIndex] : null),
    [images, selectedIndex],
  );

  const [containerRef, setContainerRef] = useState<any>();
  const [imageRef, setImageRef] = useState<any>();
  const [transform, setTransform] = useState({
    zoom: 1,
    translateX: 0,
    translateY: 0,
  });

  const [showFilters, setShowFilters] = useState(false);
  const [brightness, setBrightness] = useState<number>(100);
  const [saturation, setSaturation] = useState<number>(1);
  const [contrast, setContrast] = useState<number>(100);

  const resetFilters = useCallback(() => {
    setBrightness(100);
    setSaturation(1);
    setContrast(100);
    setTransform({
      zoom: 1,
      translateX: 0,
      translateY: 0,
    });
  }, []);

  const updateZoom = useCallback(
    (zoom: number) => {
      const newWidth = imageRef.clientWidth * zoom;
      const newHeight = imageRef.clientHeight * zoom;

      // divide by 2 because of transformOrigin being in the middle of the image.
      const overflowX = (newWidth - containerRef.clientWidth) / 2;
      const overflowY = (newHeight - containerRef.clientHeight) / 2;

      setTransform({
        ...transform,
        translateX: Math.max(
          -overflowX,
          Math.min(overflowX, transform.translateX),
        ),
        translateY: Math.max(
          -overflowY,
          Math.min(overflowY, transform.translateY),
        ),
        zoom,
      });
    },
    [imageRef, containerRef, transform],
  );
  useDragAndDrop(imageRef, containerRef, updateZoom, transform, setTransform);

  useEffect(resetFilters, [resetFilters, image]);

  const closing = useRef(false);

  const onBackgroundMouseDown = useCallback((e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    e.stopPropagation();
    closing.current = true;

    const mouseUp = () => {
      closing.current = false;
      window.removeEventListener('mouseup', mouseUp);
    };
    window.addEventListener('mouseup', mouseUp);
  }, []);

  const onBackgroundClose = useCallback(
    (e: MouseEvent) => {
      if (e.target !== e.currentTarget) return;
      if (closing.current === true && backgroundClose === true && onClose)
        onClose();
    },
    [onClose],
  );

  return open ? (
    <ModalLayer
      onMouseDown={onBackgroundMouseDown}
      onMouseUp={onBackgroundClose}
    >
      <ModalContainer onClick={(e) => e.stopPropagation()} size={size}>
        <ModalHeader>
          <Title>{title || ''}</Title>
          <Button onClick={onClose} kind='minimal' size='small'>
            <Icon size={theme.sizing.scale1000} icon={CloseIcon} />
          </Button>
        </ModalHeader>

        <ModalContent>
          {filtersEnabled && showFilters && (
            <Toolbox>
              <ToolboxHeader>
                <div>Filters</div>
                <Button onClick={resetFilters} kind='primary' size='small'>
                  Reset Filters
                </Button>
              </ToolboxHeader>

              <FormInput label={`Brightness (${brightness}%)`}>
                <Slider
                  min={0}
                  max={500}
                  value={brightness}
                  onChange={setBrightness}
                />
              </FormInput>

              <FormInput label={`Saturation (${saturation.toPrecision(2)}x)`}>
                <Slider
                  min={0}
                  max={10}
                  step={0.1}
                  value={saturation}
                  onChange={setSaturation}
                />
              </FormInput>

              <FormInput label={`Contrast (${contrast}%)`}>
                <Slider
                  min={0}
                  max={500}
                  step={1}
                  value={contrast}
                  onChange={setContrast}
                />
              </FormInput>
            </Toolbox>
          )}

          <div
            ref={setContainerRef}
            style={{ flex: 1, padding: '0px', overflow: 'hidden' }}
          >
            {image && (
              <PrimaryImage
                src={image}
                ref={setImageRef}
                style={{
                  filter: `brightness(${brightness}%) saturate(${saturation}) contrast(${contrast}%)`,
                  transform: `
                    translate(${transform.translateX}px, ${transform.translateY}px)
                    scale(${transform.zoom}, ${transform.zoom})
                  `,
                }}
              />
            )}
          </div>
        </ModalContent>

        <ModalFooter>
          <div>
            {filtersEnabled && (
              <Button
                kind='minimal'
                size='small'
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {showFilters ? 'Hide' : 'Show'} Filters
              </Button>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <FormInput label={`Zoom (${transform.zoom.toPrecision(2)}x)`}>
              <Slider
                min={1}
                max={5}
                step={0.1}
                value={transform.zoom}
                onChange={updateZoom}
              />
            </FormInput>

            <Pagination
              currentPage={(selectedIndex || 0) + 1}
              totalPages={images.length}
              setPage={(page) => setSelectedIndex(page - 1)}
            />
          </div>
        </ModalFooter>
      </ModalContainer>
    </ModalLayer>
  ) : (
    <></>
  );
};

export default ImageViewer;
