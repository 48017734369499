import { AdminLeftMenuBusinessData } from '@components/admin/left-menu/business-data';
import { AdminLeftMenuLabeler } from '@components/admin/left-menu/labeler';
import { isBusinessDataUser } from '@components/admin/utils/permissions';
import { useToken } from '@kargo/context';

export const AdminLeftMenuContainer = () => {
  const { permissions } = useToken();

  return (
    <>
      {isBusinessDataUser(permissions) ? (
        <AdminLeftMenuBusinessData />
      ) : (
        <AdminLeftMenuLabeler />
      )}
    </>
  );
};
