import { useAuth0 } from '@auth0/auth0-react';
import { KargoAvatarBubble } from '@components/kargo-ui/avatar/bubble';
import styled from '@emotion/styled';
import { useToken } from '@kargo/context/access-token-context';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import { HOSTNAME } from 'pages/_app';
import { useState } from 'react';

const StyledUserSettingsButtonContainer = styled(IconButton)`
  background: none;
  padding: 0;
  transition: opacity 0.1s ease-in;

  :hover,
  :focus-visible {
    background: none;
    opacity: 0.6;
  }
`;

const StyledUserSettingsButton = styled(KargoAvatarBubble)`
  font-size: 16px;
  height: 34px;
  width: 34px;
`;

const StyledUserSettingsMenu = styled(Menu)`
  margin-top: 8px;

  & .user-settings-menu-list {
    padding: 0;
  }
`;

const StyledUserSettingsMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;
  padding: 12px;
`;

const StyledUserIdentityMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;

  &.disabled-user-identity-menu-item {
    opacity: 1;
  }
`;

const StyledUserIdentityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1rem;
  padding: 12px 16px 4px 16px;
`;

const StyledUserAvatarBubble = styled(KargoAvatarBubble)`
  height: 30px;
  width: 30px;
  font-size: 0.875rem;
`;

const StyledSettingsLink = styled.a`
  font-size: 0.875rem;
`;

const AdminUserSettingsMenu = (): JSX.Element => {
  const { permissions } = useToken();
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <StyledUserSettingsButtonContainer
        size='small'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <StyledUserSettingsButton
          avatarHash={user?.name}
        >{`${user?.given_name?.[0]}${user?.family_name?.[0]}`}</StyledUserSettingsButton>
      </StyledUserSettingsButtonContainer>

      <StyledUserSettingsMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        classes={{
          list: 'user-settings-menu-list',
        }}
      >
        <StyledUserIdentityMenuItem
          disabled
          classes={{
            disabled: 'disabled-user-identity-menu-item',
          }}
        >
          <StyledUserIdentityContainer>
            <StyledUserAvatarBubble
              avatarHash={user?.name}
            >{`${user?.given_name?.[0]}${user?.family_name?.[0]}`}</StyledUserAvatarBubble>

            {user?.name}
          </StyledUserIdentityContainer>
        </StyledUserIdentityMenuItem>

        {permissions.includes('ops:manager_business_data') && (
          <StyledUserSettingsMenuItem>
            <Link href='/admin' passHref>
              <StyledSettingsLink>Admin Dashboard</StyledSettingsLink>
            </Link>
          </StyledUserSettingsMenuItem>
        )}

        <StyledUserSettingsMenuItem
          onClick={() => {
            // @ts-expect-error
            const intercom = window.Intercom;

            if (intercom) {
              intercom('shutdown');
            }

            logout({ returnTo: `${HOSTNAME}/login` });
          }}
        >
          <StyledSettingsLink>Sign Out</StyledSettingsLink>
        </StyledUserSettingsMenuItem>
      </StyledUserSettingsMenu>
    </>
  );
};

export { AdminUserSettingsMenu };
