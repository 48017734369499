import type { Theme } from '@emotion/react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon as MinimalDiscloseIcon } from '@heroicons/react/outline';
import { Icon } from '@kargo/ui';
import type Option from './types';

type Props = {
  onSelect: (selectInput: any) => void;
  selectedIndex: null | number;
  options: Array<Option>;
  placeholder?: string;
  disabled?: boolean;
  kind?: 'default' | 'minimal';
  size?: 'compact' | 'small' | 'medium' | 'large';
  className?: string;
  titleOverride?: React.ReactNode;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const ListboxButton = styled(Listbox.Button)<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid
    ${({ kind, open, theme }) => {
      if (kind === 'minimal') return 'transparent';
      if (open) return theme.colors.inputActive;
      return 'transparent';
    }};
  background: ${({ open, theme, disabled, kind }) => {
    if (disabled) return theme.colors.inputFillDisabled;
    if (kind === 'minimal') return 'transparent';
    if (open) return theme.colors.inputFillActive;
    return theme.colors.inputFill;
  }};
  color: ${({ theme, open }) =>
    open ? theme.colors.inputFontActive : theme.colors.inputFont};
  width: 100%;
  padding: ${({ theme, size }) =>
    size === 'compact' ? `${theme.sizing.scale500} 0` : theme.sizing.scale500};
  box-sizing: border-box;
  font-weight: 400;
`;

const ListButtonLabel = styled.div`
  display: flex;
  align-items: center;
`;

const ListboxOptions = styled(Listbox.Options)<{ children: React.ReactNode }>`
  position: absolute;
  background: ${({ theme }) => theme.colors.menuFill};
  color: ${({ theme }) => theme.colors.menuFontDefault};
  z-index: 2;
  min-width: 100%;
  color: #555;
  box-sizing: border-box;
  max-height: 400px;
  overflow: auto;
  white-space: nowrap;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%);
`;

const SelectOption = styled.div<{
  active: boolean;
  selected: boolean;
  disabled: boolean;
}>`
  cursor: pointer;
  background: ${({ active, theme }) =>
    active ? theme.colors.menuFillHover : 'inherit'};
  color: ${({ selected, active, theme, disabled }) => {
    if (disabled) return theme.colors.menuFontDisabled;
    if (active || selected) return theme.colors.menuFontSelected;
    return theme.colors.menuFontDefault;
  }};
  padding: ${({ theme }) => theme.sizing.scale500};
  font-weight: ${({ selected }) => (selected ? '400' : 'normal')};
`;

const StyledOption = styled((props: any) => <Listbox.Option {...props} />)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};

  &:last-of-type {
    border-bottom: none;
  }
`;

const Select = ({
  options,
  selectedIndex,
  onSelect,
  placeholder,
  kind = 'default',
  size = 'medium',
  disabled = false,
  titleOverride,
  className = '',
}: Props) => {
  const theme: Theme = useTheme();
  const selected = options[selectedIndex || 0];

  return (
    <Listbox onChange={onSelect} value={selectedIndex} disabled={disabled}>
      {({ open }) => (
        <Container>
          <ListboxButton
            open={open}
            disabled={disabled}
            kind={kind}
            size={size}
            className={className}
          >
            <ListButtonLabel>
              {titleOverride || selected?.label || placeholder || 'Select'}
              <Icon
                size={theme.sizing.scale800}
                icon={MinimalDiscloseIcon}
                style={{ marginLeft: '5px' }}
              />
            </ListButtonLabel>
          </ListboxButton>

          <ListboxOptions>
            {options.map((option, index) => (
              <StyledOption
                key={index}
                value={{ value: option.value, index }}
                disabled={option.disabled === true}
              >
                {(status: any) => {
                  const { active, disabled } = status;
                  return (
                    <SelectOption
                      active={active}
                      selected={selectedIndex === index}
                      disabled={disabled}
                    >
                      {option.label}
                    </SelectOption>
                  );
                }}
              </StyledOption>
            ))}
          </ListboxOptions>
        </Container>
      )}
    </Listbox>
  );
};

export default Select;
