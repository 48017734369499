import { ApolloProvider } from '@apollo/client';
import { useToken } from '@kargo/context';
import { createApolloClient } from '@services';
import { useMemo } from 'react';

type Props = {
  children: React.ReactNode;
};

const ApolloContainer = ({ children }: Props) => {
  const { token } = useToken();
  const client = useMemo(() => createApolloClient({ token }), [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloContainer;
