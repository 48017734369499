import type { OAuthError } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { setUser } from '@sentry/nextjs';
import { TokenContext, useLogger } from '@kargo/context';
import { useEffect, useState } from 'react';

type Props = { children: React.ReactNode };

// 10 Hours
const TOKEN_REFRESH_RATE = 35000;

const AuthenticatedPage = ({ children }: Props) => {
  const {
    isAuthenticated,
    isLoading,
    user,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const logger = useLogger();

  if (user) {
    setUser({
      username: user.email,
      name: user.name,
    });
  } else {
    setUser(null);
  }

  const [token, setToken] = useState<string>();
  useEffect(() => {
    if (error) {
      logger.error('failed to login due to auth0 error in frontend', error);
      return;
    }

    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    }

    const getToken = async () => {
      try {
        const newToken = await getAccessTokenSilently();
        setToken(newToken);
      } catch (err) {
        // This is needed because package auth0-react does not have types exported for errors
        const auth0Error = err as OAuthError;

        if (auth0Error.error === 'login_required') {
          loginWithRedirect();

          return;
        }

        if (auth0Error.error === 'consent_required') {
          loginWithRedirect();

          return;
        }

        throw err;
      }
    };
    setInterval(getToken, TOKEN_REFRESH_RATE);
    getToken();
  }, [isAuthenticated, error, isLoading]);

  return isLoading || !token ? (
    <div>Logging In...</div>
  ) : (
    <TokenContext.Provider value={{ token }}>{children}</TokenContext.Provider>
  );
};

export default AuthenticatedPage;
