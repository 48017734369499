import { forwardRef } from 'react';
import styled from '@emotion/styled';

type BannerType = 'success' | 'warning' | 'error';
interface Props {
  show?: boolean;
  children: React.ReactNode;
  style?: { [property: string]: number | string };
  className?: string;
  type?: BannerType;
}

const Container = styled.div<{ type: BannerType }>`
  background: ${({ theme, type }) => {
    if (type === 'success') return theme.colors.green50;
    if (type === 'error') return theme.colors.red50;
    return theme.colors.yellow200;
  }};
  border: 1px solid
    ${({ theme, type }) => {
      if (type === 'success') return theme.colors.green700;
      if (type === 'error') return theme.colors.red700;
      return theme.colors.yellow800;
    }};

  color: ${({ theme, type }) => {
    if (type === 'success') return theme.colors.green700;
    if (type === 'error') return theme.colors.red700;
    return theme.colors.yellow800;
  }};

  border-radius: ${({ theme }) => theme.sizing.scale300};
  padding: ${({ theme }) => theme.sizing.scale600};
  margin: ${({ theme }) => theme.sizing.scale200} 0;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const Banner = forwardRef<HTMLInputElement, Props>(
  ({ show, children, style = {}, className = '', type = 'warning' }, ref) => {
    if (!show) {
      return null;
    }

    return (
      <Container ref={ref} style={style} className={className} type={type}>
        {children}
      </Container>
    );
  },
);
Banner.displayName = 'Banner';

export default Banner;
