import type { ISettings } from './common/settings';
import {
  WAVE_COUNT,
  WAVE_DIRECTION,
  WAVE_EASE,
  WAVE_SPEED,
} from './common/settings';
import SkeletonSettings from './skeleton-settings';

type Props = ISettings & { children: JSX.Element };

const Skeleton = ({
  backgroundColor,
  waveColor,
  waveDirection = WAVE_DIRECTION,
  waveSpeed = WAVE_SPEED,
  waveEase = WAVE_EASE,
  waveCount = WAVE_COUNT,
  children,
}: Props) => (
  <SkeletonSettings.Provider
    value={{
      backgroundColor: backgroundColor,
      waveColor: waveColor,
      waveDirection,
      waveSpeed,
      waveEase,
      waveCount,
    }}
  >
    {children}
  </SkeletonSettings.Provider>
);

export default Skeleton;
