import type Option from '@components/kargo-ui/dropdown/types';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { Dispatch, FormEvent, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormInputType } from './types';

enum ErrorMessages {
  required = 'This is a required field',
}

type FormErrors = {
  [key: string]: {
    [key in keyof typeof ErrorMessages]: string;
  };
};

type ValueType = any;

type FormValues = {
  [key: string]: ValueType;
};

type Field = {
  field: string;
  required: boolean;
  isDirty: boolean;
};

const useForm = () => {
  const [errors, setErrors] = useState<FormErrors>();
  const [values, setValues] = useState<FormValues>({});
  const [form, setForm] = useState<Field[]>();
  const formInitalState: Field[] = [];

  const LinkState = (
    field: string,
    state: Dispatch<SetStateAction<any | undefined>>,
  ) => {
    const newFn = (value: ValueType) => {
      setValues((prev) => ({
        ...prev,
        [field]: value,
      }));
      state(value);
    };
    return newFn;
  };
  const Register = ({
    field,
    required = false,
    initialValue = () => '',
    type = FormInputType.Text,
  }: {
    field: string;
    required?: boolean;
    initialValue?: () => ValueType;
    type?: FormInputType;
  }) => {
    const newFormInput: Field = {
      field,
      required,
      isDirty: false,
    };

    formInitalState.push(newFormInput);

    const isDirty = form
      ? form.find((item: Field) => item.field === field)?.isDirty
      : false;

    const val = values[field];

    const changeValue = (value: ValueType) => {
      const newValue = value;
      const newValueInput = {
        ...values,
        [field]: newValue,
      };

      setValues(newValueInput);
      isDirty && validateForm();
    };

    if (val === undefined && initialValue()) {
      changeValue(initialValue());
    }

    const callChangeFn = () => {
      if (type === FormInputType.Text) {
        return (e: FormEvent<HTMLInputElement>) =>
          changeValue((e.target as HTMLInputElement).value);
      } else if (type === FormInputType.Checkbox) {
        return (e: FormEvent<HTMLInputElement>) =>
          changeValue((e.target as HTMLInputElement).checked);
      } else if (type === FormInputType.Dropdown) {
        return (e: Option) => changeValue(e.id);
      } else if (type === FormInputType.KargoDropdown) {
        return (e: SelectChangeEvent<unknown>) => changeValue(e.target.value);
      } else {
        throw new Error('Unsupported input type for register, ' + field);
      }
    };

    const getValueAttrName = (): string => {
      return type === FormInputType.Checkbox ? 'checked' : 'value';
    };

    return {
      [getValueAttrName()]: val !== undefined ? val : initialValue(),
      onChange: (e: any) => callChangeFn()(e),
    };
  };

  const validateForm = useCallback(() => {
    let errorList: FormErrors = {};

    form?.map(({ field, required }: { field: string; required: boolean }) => {
      const value = values[field] || '';

      if (required && (value === '' || value === undefined)) {
        const newError = {
          ...errorList,
          [field]: { required: ErrorMessages.required },
        };
        errorList = newError;
      }
    });

    const newFormState = form;
    newFormState?.map((item: Field) => (item.isDirty = true));

    setForm(newFormState);
    setErrors(errorList);

    return Object.keys(errorList).length === 0;
  }, [form, values]);

  const removeField = (field: string) => {
    const newValues = values;
    delete newValues[field];
    setValues(() => newValues);

    setForm((prev) => prev?.filter((x) => x.field !== field));
  };

  const addField = (field: string, value: ValueType) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    setForm(formInitalState);
  };

  const refreshValues = (fieldsToRemove: string[]) => {
    const newValues = values;
    fieldsToRemove.forEach((f) => {
      delete newValues[f];
    });
    setValues(() => newValues);
  };

  const isFormValid = () => {
    return validateForm();
  };

  if (!form) {
    setForm(formInitalState);
  }

  useEffect(() => {
    if (form && form[0].isDirty) {
      validateForm();
    }
  }, [values, form, validateForm]);

  useEffect(() => {
    if (formInitalState.length !== form?.length) {
      setForm(formInitalState);
      refreshValues(
        Object.keys(values).filter(
          (f) => formInitalState.filter((x) => x.field === f).length === 0,
        ),
      );
    }
  });

  return {
    register: Register,
    errors,
    isFormValid,
    values,
    form,
    linkState: LinkState,
    removeField,
    addField,
  };
};

export default useForm;
