import { sizing } from '@kargo/ui/theme/sizing';
import type { SVGProps } from 'react';
import styled from '@emotion/styled';

type Props = {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  size?: string;
  color?: string;
  iconColor?: string;
  strokeWidth?: number;
  style?: object;
};

const IconContainer = styled.div<Omit<Props, 'icon'>>`
  ${({ theme: { colors }, color, size, strokeWidth }) => `
    color: ${color ? color : colors.gray600};

    svg {
      height: ${size};
      path {
        stroke-width: ${strokeWidth};
      }
    }
  `}
`;

const Icon = ({
  icon: SvgIcon,
  size = sizing.scale800,
  color,
  iconColor,
  strokeWidth = 2,
  style,
}: Props) => {
  return (
    <IconContainer
      size={size}
      color={color}
      strokeWidth={strokeWidth}
      {...style}
    >
      <SvgIcon color={iconColor} />
    </IconContainer>
  );
};

export default Icon;
