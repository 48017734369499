import { gql, useLazyQuery } from '@apollo/client';
import { ApolloContainer, AuthenticatedPage, Logo } from '@components';
import { KargoDrawer } from '@components/kargo-ui/drawer';
import styled from '@emotion/styled';
import {
  FacilityProvider,
  FeatureFlagsProvider,
  useFacility,
  useFeatureFlagEnabled,
} from '@kargo/context';
import { MenuOpen } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useIntercom } from '@utils/intercom';
import type {
  GetBusinessSettingsPermissionsQuery,
  GetBusinessSettingsPermissionsQueryVariables,
  GetFacilitiesQuery,
} from 'generated/graphql';
import moment from 'moment-timezone';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { DashboardCreateActionsMenu } from './dashboard-header/dashboard-create-actions-menu';
import { DashboardHeaderNavigation } from './dashboard-header/dashboard-header-navigation';
import type { DashboardBreadcrumb } from './dashboard-header/dashboard-header-navigation/dashboard-header-navigation';
import { DashboardUserSettingsMenu } from './dashboard-header/dashboard-user-settings-menu';
import { DashboardNav } from './dashboard-nav';

const FACILITIES_CACHE_VERSION = '1.1';
const SELECTED_FACILITY_CACHE_VERSION = '1.1';

type FacilityOption = {
  index?: number;
  value: {
    name: string | null;
    id: number;
    slug: string;
    businessId: number;
    businessSlug: string;
    timezoneName: string;
    timezoneAbbr: string;
  };
};

type SetSelectedProps = FacilityOption & {
  redirect?: boolean;
};

const GET_FACILITIES = gql`
  query GetFacilities {
    businesses {
      id
      name
      slug
      facilities {
        id
        name
        slug
        timezone {
          name
        }
        address {
          city
          state
        }
        location {
          lat
          lng
        }
      }
    }
  }
`;

const GET_BUSINESS_SETTINGS_PERMISSION = gql`
  query GetBusinessSettingsPermissions($businessId: Int!) {
    business(id: $businessId) {
      users {
        hasPermission
      }
    }
  }
`;

export const SIDE_NAV_WIDTH = '225px';
export const DASHBOARD_NAV_HEADER_HEIGHT = '60px';

const StyledDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors.contentPrimary};
  background: ${(p) => p.theme.colors.backgroundPrimary};
  overflow: hidden;
`;

type StyledDashboardContentContainerProps = {
  isNavDrawerOpen: boolean;
};

const StyledDashboardContentContainer = styled.div<StyledDashboardContentContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${DASHBOARD_NAV_HEADER_HEIGHT});
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH})` : '100%'};
  margin-left: ${(p) => (p.isNavDrawerOpen ? `${SIDE_NAV_WIDTH}` : 0)};
  transition: all 0.2s ease-out;

  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.contentPrimary};
  overflow: auto;
`;

type StyledDashboardHeaderContainerProps = {
  isNavDrawerOpen: boolean;
};

const StyledDashboardHeaderContainer = styled.div<StyledDashboardHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH} + 55px)` : '100%'};
  margin-left: ${(p) =>
    p.isNavDrawerOpen ? `calc(${SIDE_NAV_WIDTH} - 55px)` : 0};
  transition: all 0.2s ease-out;

  padding: 8px 24px;
  background: ${(p) => p.theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${(p) => p.theme.colors.borderPrimary};
`;

const StyledDashboardHeaderMain = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledOpenNavDrawerIconButton = styled(IconButton)`
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
    background: none;
  }
`;

const StyledOpenNavDrawerIcon = styled(MenuOpen)`
  transform: scaleX(-1);
`;

const StyledDashboardHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledNavContainer = styled.div`
  width: ${SIDE_NAV_WIDTH};
  min-height: 100vh;
  color: ${(p) => p.theme.colors.contentPrimary};
  background-color: ${(p) => p.theme.colors.sidebarBackground};
  overflow: auto;
`;

const StyledNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledLogoLink = styled.a`
  margin-left: 26px;

  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledCloseNavDrawerIconButton = styled(IconButton)`
  padding-top: 10px;
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
  }

  :focus-visible {
    border-radius: 0;
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledCloseNavDrawerIcon = styled(MenuOpen)`
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
`;

const useFacilitySelection = (
  facilities: Array<{
    name: string | null;
    id: number;
    slug: string;
    businessId: number;
    businessSlug: string;
    businessName: string;
    timezone: { name: string };
  }>,
) => {
  const router = useRouter();

  const [selectedValue, setSelectedValue] = useState<{
    name: string | null;
    id: number;
    slug: string;
    businessId: number;
    businessSlug: string;
    timezoneName: string;
    timezoneAbbr: string;
  } | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  useEffect(() => {
    if (facilities.length > 0) {
      let index = 0;
      const cached = loadSelectedFacility();

      const parsedValue = cached ? JSON.parse(cached) : undefined;
      if (parsedValue) {
        const { id: facilityId } = parsedValue;
        index = findSelectedOption(facilityId);
      }

      setSelected({
        index,
        value: options[index].value,
        redirect: false,
      });
    }
  }, [facilities]);

  const hasMultipleBusinesses =
    new Set(facilities.map(({ businessId }) => businessId)).size > 1;

  const options = facilities.map((facility) => ({
    label: hasMultipleBusinesses
      ? `${facility.businessName} - ${facility.name}`
      : facility.name || '',
    value: {
      name: facility.name,
      id: facility.id,
      slug: facility.slug,
      businessId: facility.businessId,
      businessSlug: facility.businessSlug,
      timezoneName: facility.timezone?.name || moment.tz.guess(),
      timezoneAbbr: facility.timezone
        ? moment.tz(facility.timezone.name).zoneAbbr()
        : moment.tz(moment.tz.guess()).zoneAbbr(),
    },
  }));

  const findSelectedOption = (facilityId: number) => {
    const foundIndex = options.findIndex(
      ({ value: { id } }) => id === facilityId,
    );

    return foundIndex !== -1 ? foundIndex : 0;
  };

  const saveSelectedFacility = (value: object) => {
    sessionStorage.setItem(
      `selectedFacility_${SELECTED_FACILITY_CACHE_VERSION}`,
      JSON.stringify(value),
    );

    localStorage.setItem(
      `selectedFacility_${SELECTED_FACILITY_CACHE_VERSION}`,
      JSON.stringify(value),
    );
  };

  const loadSelectedFacility = () => {
    const session = sessionStorage.getItem(
      `selectedFacility_${SELECTED_FACILITY_CACHE_VERSION}`,
    );

    const local = localStorage.getItem(
      `selectedFacility_${SELECTED_FACILITY_CACHE_VERSION}`,
    );

    return session ?? local;
  };

  const setSelected = ({ index, value, redirect = true }: SetSelectedProps) => {
    if (value) {
      saveSelectedFacility(value);
    } else {
      sessionStorage.removeItem(
        `selectedFacility_${SELECTED_FACILITY_CACHE_VERSION}`,
      );
    }

    let facilityIndex = index;
    if (!facilityIndex) {
      facilityIndex = findSelectedOption(value.id);
    }

    setSelectedIndex(facilityIndex);
    setSelectedValue(value);

    if (redirect) {
      if (
        ['/shipments/new', '/shipments/[shipmentId]'].includes(router.pathname)
      ) {
        router.push('/shipments');
      } else if (router.pathname === '/orders/[orderId]') {
        router.push('/orders');
      } else if (
        [
          '/exceptions/[exceptionId]',
          '/exceptions/settings',
          '/exceptions/create',
        ].includes(router.pathname)
      ) {
        router.push('/exceptions');
      }
    }
  };

  const setById = (id: number) => {
    const idx = options.findIndex((facility) => facility.value.id === id);
    setSelected({ index: idx, value: options[idx].value, redirect: false });
  };

  return {
    selectedValue,
    selectedIndex,
    setSelected,
    setById,
    options,
  };
};

type Props = {
  children: React.ReactNode;
  breadcrumbs?: DashboardBreadcrumb[];
};

const DashboardLayout = (props: Props) => {
  return (
    <AuthenticatedPage>
      <ApolloContainer>
        <FeatureFlagsProvider>
          <Facilities {...props} />
        </FeatureFlagsProvider>
      </ApolloContainer>
    </AuthenticatedPage>
  );
};

const Facilities = ({ children, breadcrumbs }: Props) => {
  useIntercom();
  const { setFacility } = useFacility();
  const [getFacilities, { data }] =
    useLazyQuery<GetFacilitiesQuery>(GET_FACILITIES);

  useEffect(() => {
    getFacilities();
  }, []);

  const [
    getBusinessSettingsPermissions,
    { data: businessSettingsPermissions },
  ] = useLazyQuery<
    GetBusinessSettingsPermissionsQuery,
    GetBusinessSettingsPermissionsQueryVariables
  >(GET_BUSINESS_SETTINGS_PERMISSION);

  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true);

  const facilities = useMemo(() => {
    if (!data) {
      const cached = sessionStorage.getItem(
        `facilities_${FACILITIES_CACHE_VERSION}`,
      );
      if (cached) return JSON.parse(cached);
      else return [];
    }

    const value =
      data?.businesses.flatMap((business) => {
        return business.facilities.map((facility) => ({
          ...facility,
          businessId: business.id,
          businessSlug: business.slug,
          businessName: business.name,
        }));
      }) || [];

    sessionStorage.setItem(
      `facilities_${FACILITIES_CACHE_VERSION}`,
      JSON.stringify(value),
    );

    return value;
  }, [data]);

  const {
    options: facilityOptions,
    setSelected: onFacilitySelected,
    setById: setFacilityById,
    selectedIndex: selectedFacilityIndex,
    selectedValue: selectedFacility,
  } = useFacilitySelection(facilities);

  useEffect(() => {
    if (!selectedFacility) return;
    getBusinessSettingsPermissions({
      variables: { businessId: selectedFacility.businessId },
    });
    setFacility(selectedFacility);
  }, [selectedFacility?.businessId]);

  const hasBusinessSettingsPermissions = useMemo(() => {
    // set the business selection for future reference.
    if (selectedFacility) {
      sessionStorage.setItem(
        'selectedBusinessId',
        selectedFacility.businessId.toString(),
      );
    }

    // get the business id you have selected, favoring the fetched version,
    // then the session value, and then returning false if nothing is selected.
    const businessId =
      selectedFacility?.businessId ||
      sessionStorage.getItem('selectedBusinessId') ||
      undefined;
    if (!businessId) return false;

    // return the local value if you haven't fetched yet
    const sessionValueKey = `businessSettingsPermission[${businessId}]`;
    if (!businessSettingsPermissions) {
      const sessionValue = sessionStorage.getItem(sessionValueKey) === 'true';
      return sessionValue !== undefined ? sessionValue : false;
    }

    // you've fetched; set the update value and return it.
    const hasPermission =
      businessSettingsPermissions.business.users.hasPermission;

    // set for use
    sessionStorage.setItem(
      sessionValueKey,
      hasPermission === true ? 'true' : 'false',
    );

    return hasPermission;
  }, [businessSettingsPermissions, selectedFacility]);

  const isScheduleShipmentEnabled = useFeatureFlagEnabled({
    featureId: 'shipment_checkin_20220119',
    enabledVariants: ['enabled'],
  });

  return (
    <StyledDashboardContainer>
      <StyledDashboardHeaderContainer isNavDrawerOpen={isNavDrawerOpen}>
        <StyledDashboardHeaderMain>
          <StyledOpenNavDrawerIconButton
            onClick={() => {
              setIsNavDrawerOpen(true);
            }}
          >
            <StyledOpenNavDrawerIcon />
          </StyledOpenNavDrawerIconButton>

          {facilityOptions.length > 0 && (
            <DashboardHeaderNavigation
              breadcrumbs={breadcrumbs}
              facilityOptions={facilityOptions}
              selectedFacilityIndex={selectedFacilityIndex}
              onFacilitySelected={onFacilitySelected}
            />
          )}
        </StyledDashboardHeaderMain>

        <StyledDashboardHeaderActions>
          {isScheduleShipmentEnabled && <DashboardCreateActionsMenu />}

          <DashboardUserSettingsMenu />
        </StyledDashboardHeaderActions>
      </StyledDashboardHeaderContainer>

      <StyledDashboardContentContainer isNavDrawerOpen={isNavDrawerOpen}>
        <FacilityProvider
          facility={selectedFacility}
          setFacility={(facilityId: number) => {
            setFacilityById(facilityId);
          }}
        >
          {children}
        </FacilityProvider>
      </StyledDashboardContentContainer>

      <KargoDrawer
        variant='persistent'
        anchor='left'
        classes={{
          paper: 'dashboard-nav-drawer',
        }}
        open={isNavDrawerOpen}
      >
        <StyledNavContainer>
          <StyledNavLogoContainer>
            <Link href='/' passHref>
              <StyledLogoLink>
                <Logo />
              </StyledLogoLink>
            </Link>

            <StyledCloseNavDrawerIconButton
              onClick={() => {
                setIsNavDrawerOpen(false);
              }}
            >
              <StyledCloseNavDrawerIcon />
            </StyledCloseNavDrawerIconButton>
          </StyledNavLogoContainer>

          <DashboardNav
            hasBusinessSettingsPermissions={hasBusinessSettingsPermissions}
          />
        </StyledNavContainer>
      </KargoDrawer>
    </StyledDashboardContainer>
  );
};

export { DashboardLayout };
